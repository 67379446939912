export class LocalStorageService {
    public static Set<T>(key : string , value : T) : void{
        localStorage.setItem(key,JSON.stringify(value))
    }

    public static Get<T>(key : string) : T | null{
        var item = localStorage.getItem(key)
        if(!item)
            return null;

        return JSON.parse(item)
    }
}