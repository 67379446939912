import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Box, IconButton, InputAdornment } from "@mui/material";
import Input from "components/form/Input";
import { useState } from "react";
import { Control } from "react-hook-form";
import { UserDetailsForm } from "types/form.types";
import regex from "utils/regex";

type UserFormProps = {
  control: Control<UserDetailsForm>;
};

const UserForm = ({ control }: UserFormProps) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () =>
    setShowPassword((show: boolean) => !show);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "20px",
        width: "70%",
      }}
    >
      <Input
        label="Email"
        name="email"
        control={control}
        rules={{
          required: "Email is required",
          pattern: {
            value: regex.email,
            message: "Please enter valid email",
          },
        }}
      />

      <Input
        label="Password"
        name="password"
        control={control}
        type={showPassword ? "text" : "password"}
        rules={{
          required: "Password is required",
        }}
        endicon={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              //   onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
      />
    </Box>
  );
};

export default UserForm;
