import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useAppSelector } from "store/store-hooks";
import { RouteComponentProps } from "types/common.types";
import routes from "./routes";

const AuthRoute = (props: RouteComponentProps) => {
  const { isLoggedIn } = useAppSelector((state) => state.userReducer.value);
  const navigate= useNavigate();

  useEffect(() => {
    if (isLoggedIn) {
      navigate(routes.home);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoggedIn) {
    return <></>;
  }

  const { Component, ...rest } = props;
  return <Component {...rest} />;
};

export default AuthRoute;
