import axios, { CancelToken } from "axios";
import { EnvironmentService } from "./environmentservice";
import { UserService } from "./userservice";

export class HttpService {
  public static async Post<TRequest>(endpoint: string, body: TRequest) {
    if (endpoint.startsWith("/"))
      throw new Error("Endpoint should starts with /");

    let token = UserService.GetAccessToken();
    return await axios.post(
      `${EnvironmentService.getServerHost()}/${endpoint}`,
      body,
      token
        ? {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        : {}
    );
  }

  public static async Get(endpoint: string) {
    if (endpoint.startsWith("/"))
      throw new Error("Endpoint should not starts with /");

    let token = UserService.GetAccessToken();
    return await axios.get(
      `${EnvironmentService.getServerHost()}/${endpoint}`,
      token
        ? {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        : {}
    );
  }

  public static async CoreApiGet(endpoint: string) {
    if (endpoint.startsWith("/"))
      throw new Error("Endpoint should not starts with /");

    let token = UserService.GetAccessToken();
    return await axios.get(
      `${EnvironmentService.getCoreServerHost()}/${endpoint}`,
      token
        ? {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        : {}
    );
  }
  public static async CoreApiGetCalender(
    endpoint: string,
    requestBody: object | null = null
  ) {
    if (endpoint.startsWith("/"))
      throw new Error("Endpoint should not start with /");

    let token = UserService.GetAccessToken();

    return await axios.get(
      `${EnvironmentService.getCoreServerHost()}/${endpoint}`,
      {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
        data: requestBody,
      }
    );
  }

  public static async CoreApiPost<TRequest>(endpoint: string, body: TRequest) {
    if (endpoint.startsWith("/"))
      throw new Error("Endpoint should starts with /");

    let token = UserService.GetAccessToken();
    // return
    return await axios.post(
      `${EnvironmentService.getCoreServerHost()}/${endpoint}`,
      body,
      token
        ? {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        : {}
    );
  }

  public static async CoreApiPostForCancel<TRequest>(
    endpoint: string,
    body: TRequest,
    cancelToken?: CancelToken
  ) {
    if (endpoint.startsWith("/"))
      throw new Error("Endpoint should start with /");

    let token = UserService.GetAccessToken();

    // Create the request configuration object
    const config = {
      headers: {
        Authorization: token ? `Bearer ${token}` : undefined,
      },
      cancelToken,
    };

    return await axios.post(
      `${EnvironmentService.getCoreServerHost()}/${endpoint}`,
      body,
      config
    );
  }

  public static async CoreApiPostWithoutBody(endpoint: string) {
    if (endpoint.startsWith("/")) {
      throw new Error("Endpoint should start with /");
    }

    let token = UserService.GetAccessToken();
    return await axios.post(
      `${EnvironmentService.getCoreServerHost()}/${endpoint}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }
}
