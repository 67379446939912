import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { CallService } from "../../services/callservice";
import { AppThunk } from "store/store-hooks";
import { toast } from "react-toastify";
type SortDirection = "asc" | "desc"; // Allowed sort directions

interface Call {
  uuid: string;
  email: string | null;
  dialed_number: string | null;
  caller: string;
  dialed_call_duration: number;
  created_at: { value: number | string | Date };
  revenue: string | null;
  duplicate: boolean;
  repeat: boolean;
  recording_url: string | null;
  converted: boolean;
  is_disputed: boolean | null;
  is_approved: boolean | null;
  buyer_name: string | null;
  campaign_name: string;
  target_name: string | null;
  elocal_call_price: string | null;
  elocal_category_name: string | null;
  reviewer_reason: string | null;
  reviewer_notes: string | null;
  isAdmin: boolean;
  pageName: string;
  handlePopupClose: () => void;
  sortBy: string; // The key or field name to sort by
  sortOrder: SortDirection;
}

interface CallState {
  calls: any[];
  list: any;
  loading: boolean;
  error: string | null;
  totalPages: number;
  currentPage: number;
}
interface IFetchCall {
  startDate?: Date | null;
  endDate?: Date | null;
  pageNumber?: number;
  campaignName?: string;
  targetName?: string;
  buyerName?: string;
  isAdmin?: boolean;
  pageName?: string;
  sortBy?: string; // The key or field name to sort by
  sortOrder?: SortDirection;
  callerId?: string;
}

const initialState: CallState = {
  calls: [],
  list: [],
  loading: false,
  error: null,
  totalPages: 0,
  currentPage: 1,
};

const callsSlice = createSlice({
  name: "calls",
  initialState,
  reducers: {
    setCalls: (state, action: PayloadAction<Call[]>) => {
      state.calls = action.payload;
      state.loading = false;
      state.error = null;
    },
    setList: (state, action: PayloadAction<Call[]>) => {
      state.list = action.payload;
    },
    setTotalPages(state, action: PayloadAction<number>) {
      state.totalPages = action.payload;
    },
    setCurrentPage(state, action: PayloadAction<number>) {
      state.currentPage = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const {
  setCalls,
  setList,
  setLoading,
  setError,
  setCurrentPage,
  setTotalPages,
} = callsSlice.actions;

export const selectCalls = (state: RootState) => state.calls.calls;
export const selectList = (state: RootState) => state.calls.list;

export const selectLoading = (state: RootState) => state.calls.loading;
export const selectError = (state: RootState) => state.calls.error;
export const selectTotalPages = (state: any) => state.calls.totalPages;
export const selectCurrentPage = (state: any) => state.calls.currentPage;

export const fetchCalls =
  ({
    startDate,
    endDate,
    pageNumber,
    campaignName,
    targetName,
    buyerName,
    isAdmin = false,
    pageName,
    sortBy = "",
    sortOrder = "asc",
    callerId,
  }: IFetchCall): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await CallService.getAllCallLogs({
        startDate,
        endDate,
        pageNumber,
        campaignName,
        targetName,
        buyerName,
        isAdmin,
        pageName,
        sortBy,
        sortOrder,
        callerId,
      });
      const { calls, totalPages, currentPage } = response.data.body;
      dispatch(setCalls(calls));
      dispatch(setTotalPages(totalPages));
      dispatch(setCurrentPage(currentPage));
    } catch (error: any) {
      dispatch(setError(error.message || "An error occurred."));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const fetchList = (): AppThunk => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const response = await CallService.getList();
    const { records } = response.data.body;
    dispatch(setList(records));
  } catch (error: any) {
    dispatch(setError(error.message || "An error occurred."));
  } finally {
    dispatch(setLoading(false));
  }
};

export const updateCallLogStatus =({
    uuid,
    is_approved,
    is_disputed,
    startDate,
    endDate,
    pageNumber,
    reviewer_reason,
    reviewer_notes,
    isAdmin,
    pageName,
    handlePopupClose,}: {
    uuid: string;
    is_approved: boolean;
    is_disputed: boolean;
    startDate?: Date | null;
    endDate?: Date | null;
    pageNumber?: number;
    reviewer_reason: string;
    reviewer_notes: string;
    isAdmin: boolean;
    pageName: string;
    handlePopupClose: () => void;
    revenue?: any;
  }): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await CallService.updateCallStatus({
        uuid,
        is_approved,
        is_disputed,
        reviewer_reason,
        reviewer_notes,
        revenue: is_approved ? 0 : undefined,
      });
      if ((response.data.message = "Success")) {
        let message;
        if (is_approved && !is_disputed) {
          message = "Call Approved Successfully";
        } else if (!is_approved && !is_disputed) {
          message = "Call Denied Successfully";
        } else {
          message = "Call marked as disputed";
        }
        toast.success(message);
        dispatch(
          fetchCalls({ startDate, endDate, pageNumber, isAdmin, pageName })
        );
      }
    } catch (error: any) {
      dispatch(setError(error.message || "An error occurred."));
    } finally {
      handlePopupClose();
    }
  };

export default callsSlice.reducer;
