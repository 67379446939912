import { Box, Button } from '@mui/material';
import Switch, { SwitchProps } from '@mui/material/Switch';
import { styled } from '@mui/material/styles';


function Security() {


  const IOSSwitch = styled((props: SwitchProps) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: "26.5px",
    height: "17.2px",
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(10px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#0B5FFF',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '4px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: "14.11px",
      height: "14.11px",
    },
    '& .MuiSwitch-track': {
      borderRadius: 52.25,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }));
  return (
    <Box>
      <h2 className="settings_heading">Security</h2>
      <div className="settings_line"></div>
      <div className='flex justify-between mt-1 w-[50%]'>
        <div className='text-lg font-medium'>
          Pll Redaction
        </div>
        <div>
          <IOSSwitch />
        </div>
      </div>
      <div className='flex justify-between mt-1 w-[50%]'>
        <div className='text-lg font-medium'>
        Immediate data deletion
        </div>
        <div>
          <IOSSwitch />
        </div>
      </div>
      <div className='flex justify-between mt-1 w-[50%]'>
        <div className='text-lg font-medium'>
        Meeting summary 
        </div>
        <div>
          <IOSSwitch />
        </div>
      </div>
      <div className='flex justify-between mt-1 w-[50%]'>
        <div className='text-lg font-medium'>
        Meeting insights
        </div>
        <div>
          <IOSSwitch />
        </div>
      </div>
      <div className='flex justify-between mt-1 w-[50%]'>
        <div className='text-lg font-medium'>
        Hide attendee name 
        </div>
        <div>
          <IOSSwitch />
        </div>
      </div>
      <div className='flex justify-between mt-1 w-[50%]'>
        <div className='text-lg font-medium'>
        Two factor Authentication
        </div>
        <div>
          <IOSSwitch />
        </div>
      </div>
      <div className="settings_lines"></div>
      <h3 className='settings_sub-heading mt-1'>Change Password</h3>

      <p className='settings_light-text'>Please use this section to update your password</p>

      <Box sx={{ mt: '10px', mb: '5px' }}>
        Previous Password
      </Box>

      <input className='input' type="text" />

      <Box sx={{ mt: '10px', mb: '5px' }}>
        New Password
      </Box>

      <input className='input' type="text" />

      <Box sx={{ mt: '10px', mb: '5px' }}>
        New Password
      </Box>

      <input className='input' type="text" />
      <br />

      <Button className="btn-black" sx={{ mt: '40px' , mb: "40px" }}>Save</Button>
    </Box>
  )
}

export default Security