import { HttpService } from "./httpservice";

type SortDirection = "asc" | "desc"; // Allowed sort directions
interface IFetchCall {
  startDate?: Date | null;
  endDate?: Date | null;
  pageNumber?: number;
  campaignName?: string;
  targetName?: string;
  buyerName?: string;
  isAdmin?: boolean;
  pageName?: string;
  sortBy: string; // The key or field name to sort by
  sortOrder: SortDirection;
  callerId?: string;
}
export class CallService {
  public static async getAllCallLogs({
    startDate,
    endDate,
    pageNumber = 1,
    campaignName,
    targetName,
    buyerName,
    isAdmin,
    pageName,
    sortBy,
    sortOrder,
    callerId,
  }: IFetchCall) {
    let url = `users/call-logs?page=${pageNumber}`;
    if (startDate && endDate) {
      const startParam = startDate.toISOString().split("T")[0];
      const endParam = endDate.toISOString().split("T")[0];
      url += `&startDate=${startParam}&endDate=${endParam}`;
    }
    if (campaignName) url += `&campaignName=${campaignName}`;
    if (targetName) url += `&targetName=${targetName}`;
    if (buyerName) url += `&buyerName=${buyerName}`;
    if (isAdmin && pageName === "Home") url += `&showApprovedNDisputed=${true}`;
    if (pageName === "Home") url += `&showRevenueData=${true}`;
    if (sortBy && sortOrder)
      url += `&sortBy=${sortBy}&sortOrder=${sortOrder.toUpperCase()}`;
    if (callerId) url += `&callerPhone=${callerId}`;

    const response = await HttpService.CoreApiGet(url);

    return response;
  }

  public static updateCallStatus(body: {
    uuid: string;
    is_approved: boolean;
    is_disputed: boolean;
    reviewer_reason: string;
    reviewer_notes: string;
    revenue: any;
  }) {
    return HttpService.CoreApiPost(`users/call-logs-update`, body);
  }

  public static async getList() {
    let url = `users/list`;
    const response = await HttpService.CoreApiGet(url);

    return response;
  }
}
