/* eslint-disable @typescript-eslint/no-explicit-any */
import { createTheme, responsiveFontSizes } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface TypographyVariants {
    [key: string]: any;
  }
  interface TypographyVariantsOptions {
    [key: string]: any;
  }
  interface Palette {
    [key: string]: any;
  }
  interface PaletteOptions {
    [key: string]: any;
  }
}

// Create a theme instance.
let theme = createTheme({
  palette: {
    primary: {
      main: "#0b5fff",
    },
    success: {
      main: "#77e457",
    },
    error: {
      main: "#EC0909",
    },
    custom: {
      label: "#0F1826",
      heading: "#231f20",
      disabled: "#efefef",
      border: "#D7DADE",
      background: "#eef2f9",
      chatBg: "#EAF1FF",
    },
    textColor: {
      light: "#6B6C7E",
      dark: "#333",
    },
  },

  typography: {
    fontFamily: ["Inter", "sans-serif"].join(", "),
  },
});

// styles overrides
theme = createTheme(theme, {
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          "& *": {
            "&::-webkit-scrollbar ": {
              width: "4px",
              height: "4px",
            },

            "&::-webkit-scrollbar-track": {
              backgroundColor: "#e8e9eb",
              borderRadius: "4px",
            },

            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#bbbec2",
              borderRadius: "4px",
            },

            "&::-webkit-scrollbar-thumb:hover": {
              backgroundColor: "#0F1826",
            },
          },
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          "&.Mui-disabled": {
            cursor: "not-allowed",
            pointerEvents: "all",
            "&:hover": {
              boxShadow: "none",
            },
          },
        },
      },
    },

    MuiModal: {
      styleOverrides: {
        root: {
          background:
            "linear-gradient(116.57deg, rgba(89, 91, 92, 0.6) -9.43%, rgba(39, 142, 255, 0.114) 99.12%)",
          backgroundColor: "none",
        },
      },
    },

    MuiDialog: {
      styleOverrides: {
        root: {
          position: "absolute",
          background:
            "linear-gradient(116.57deg, rgba(89, 91, 92, 0.6) -9.43%, rgba(39, 142, 255, 0.114) 99.12%)",
          "& .MuiModal-backdrop": {
            backgroundColor: "transparent",
            position: "absolute",
          },
          "& .MuiPaper-root": {
            maxWidth: "none",
            maxHeight: "none",
          },
        },
      },
    },

    MuiInputLabel: {
      styleOverrides: {
        root: {
          "&.MuiInputLabel-root.clip": {
            maxWidth: "90%",
          },
          "&.MuiInputLabel-root": {
            maxWidth: "100%",
          },
          fontSize: "14px",
          fontWeight: 500,
          color: theme.palette.custom.label,
          "&.Mui-focused": {
            color: theme.palette.custom.label,
          },
          transform: "none",
        },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        root: {
          "label + &": {
            marginTop: "25px",
          },
          height: "50px",

          boxSizing: "border-box",
          borderRadius: 5,
          position: "relative",
          borderWidth: 2,
          borderStyle: "solid",
          borderColor: theme.palette.custom.disabled,
          fontSize: "14px",
          padding: "10px 12px",
          [theme.breakpoints.down("sm")]: {
            fontSize: "12px",
            height: "43px",
          },
          "&.MuiInputBase-multiline": {
            height: "auto",
          },
          "&.Mui-error": {
            borderColor: theme.palette.error.main,
          },
          "&.Mui-focused": {
            borderColor: theme.palette.primary.main,
          },
          "& input.Mui-disabled": {
            cursor: "not-allowed",
          },
          "& input::-webkit-outer-spin-button": {
            display: "none",
            MozAppearance: "none",
          },
          "& input::-webkit-inner-spin-button": {
            display: "none",
            MozAppearance: "none",
          },
        },
      },
    },

    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: "10px",
          color: theme.palette.error.main,
          fontWeight: "bold",
          position: "absolute",
          bottom: "-17px",
          height: "15px",
          marginLeft: "0px",
          [theme.breakpoints.down("sm")]: {
            fontSize: "8px",
            bottom: "-14px",
          },
        },
      },
    },
  },
});

const customTypography = {
  // add custom typography
  hideScroll: {
    "&::-webkit-scrollbar ": {
      display: "none",
    },
  },
};

theme = {
  ...theme,
  typography: {
    ...theme.typography,
    ...customTypography,
  },
};

theme = responsiveFontSizes(theme);

export default theme;
