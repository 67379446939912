import { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  InputLabel,
  MenuItem,
  FormControl,
  Typography,
  Button,
  Pagination,
  IconButton,
  OutlinedInput,
  Grid,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MicIcon from "@mui/icons-material/Mic";
import { useSelector } from "react-redux";
import {
  fetchCalls,
  selectCalls,
  selectLoading,
  selectError,
  selectTotalPages,
  updateCallLogStatus,
  fetchList,
  selectList,
} from "../../store/slices/call-slice";
import { useAppDispatch, useAppSelector } from "store/store-hooks";
import { Link, useLocation } from "react-router-dom";
import moment from "moment";
import { selectUser, clearUserData } from "store/slices/user-slice";
import DisputePopupModal from "components/common/DisputePopupModal";
import { capitalizeFirstLetter } from "utils/functions";
import NewTable from "components/common/table/newTable";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getStyles } from "utils/common";
import defaultStyles, {
  StylesClasses,
} from "../../layout/DashboardLayout/styles";
import routes from "router/routes";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const dispatch = useAppDispatch();
  const callData = useSelector(selectCalls);
  const loading = useSelector(selectLoading);
  const error = useSelector(selectError);
  const location = useLocation();
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [pageNumber, setPageNumber] = useState(1);
  const totalPages = useSelector(selectTotalPages);
  const [pageName, setPageName] = useState("Home");
  const userSelector = useSelector(selectUser);
  const [selectedTargetName, selectTargetName] = useState<string>("");
  const [selectedCampaignName, selectCampaignName] = useState<string>("");
  const [selectedBuyerName, selectBuyerName] = useState<string>("");
  const [selectedCallerId, selectCallerId] = useState<string>("");
  const [isDisputePopupOpen, setDisputePopupOpen] = useState(false);
  const [reason, setReason] = useState<string>("");
  const [comment, setComment] = useState<string>("");
  const [popupUuid, setPopupUuid] = useState<string>("");
  const [onlyView, setOnlyView] = useState(false);

  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: "asc" | "desc";
  }>({
    key: "",
    direction: "asc",
  });

  const optionList =  useSelector(selectList);

  const fetchData = async (pgNumber = 1) => {
    let page_name = location.pathname === "/" ? "Home" : "Call Logs";
    setPageName(page_name);

    dispatch(fetchList());

    if (!startDate && !endDate) {
      let end_date = new Date();
      let start_date = new Date(
        moment(new Date()).subtract(15, "days").format("MM/DD/YYYY")
      );
      setStartDate(start_date);
      setEndDate(end_date);
      dispatch(
        fetchCalls({
          pageNumber: pgNumber,
          startDate: start_date,
          endDate: end_date,
          campaignName: selectedCampaignName,
          buyerName: selectedBuyerName,
          targetName: selectedTargetName,
          isAdmin: userSelector.is_admin ? true : false,
          pageName: page_name,
          sortBy: sortConfig.key,
          sortOrder: sortConfig.direction,
          callerId: selectedCallerId,
        })
      );
    } else {
      dispatch(
        fetchCalls({
          pageNumber: pgNumber,
          startDate,
          endDate,
          isAdmin: userSelector.is_admin ? true : false,
          pageName: page_name,
          sortBy: sortConfig.key,
          sortOrder: sortConfig.direction,
        })
      );
    }
  };

  // Fetch data whenever the route changes
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [location.pathname, dispatch, pageNumber, sortConfig, startDate, endDate]);

  const handleSortChange = (sortKey: string) => {
    setSortConfig((prevConfig) => ({
      key: sortKey,
      direction:
        prevConfig.key === sortKey && prevConfig.direction === "asc"
          ? "desc"
          : "asc",
    }));
    setPageNumber(1);
  };

  const handleApprove = (uuid: string) => {
    dispatch(
      updateCallLogStatus({
        uuid,
        is_approved: true,
        is_disputed: false,
        startDate,
        endDate,
        pageNumber,
        reviewer_reason: reason,
        reviewer_notes: comment,
        revenue: 0,
        isAdmin: userSelector.is_admin ? true : false,
        handlePopupClose,
        pageName,
      })
    );
  };

  const handleEndDateChange = (date: Date | null) => {
    if (date) {
      date.setHours(0, 0, 0, 0);
    }
    setEndDate(date);
  };
  const handleDispute = (uuid: string) => {
    dispatch(
      updateCallLogStatus({
        uuid,
        is_disputed: true,
        is_approved: false,
        startDate,
        endDate,
        pageNumber,
        reviewer_reason: reason,
        reviewer_notes: comment,
        isAdmin: userSelector.is_admin ? true : false,
        handlePopupClose,
        pageName,
      })
    );
    setReason("");
    setComment("");
  };
  const handleDeny = (uuid: string) => {
    dispatch(
      updateCallLogStatus({
        uuid,
        is_disputed: false,
        is_approved: false,
        startDate,
        endDate,
        pageNumber,
        reviewer_reason: reason,
        reviewer_notes: comment,
        isAdmin: userSelector.is_admin ? true : false,
        handlePopupClose,
        pageName,
      })
    );
  };
  const handlePopupClose = () => {
    setDisputePopupOpen(false);
    setOnlyView(false);
  };

  const handleDateRangeChange = () => {
    setPageNumber(1);
    dispatch(
      fetchCalls({
        startDate,
        endDate,
        pageNumber: 1,
        campaignName: selectedCampaignName,
        targetName: selectedTargetName,
        buyerName: selectedBuyerName,
        isAdmin: userSelector.is_admin ? true : false,
        pageName: pageName,
        callerId: selectedCallerId,
      })
    );
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPageNumber(value);
    fetchData(value);
  };

  const formatTime = (seconds: number) => {
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;

    if (hrs > 0) {
      return `${hrs.toString().padStart(2, "0")}:${mins
        .toString()
        .padStart(2, "0")}:${secs.toString().padStart(2, "0")}`;
    } else {
      return `${mins.toString().padStart(2, "0")}:${secs
        .toString()
        .padStart(2, "0")}`;
    }
  };

  const formattedData =
    callData && callData.length > 0
      ? callData.map((item: any) => {
          console.log("callData.length", item);

          const createdAt = new Date(item.created_at.value);
          return {
            ...item,
            revenue:
              item.revenue === null || item?.review_status === "approved"
                ? "$0"
                : `$${item.revenue}`,
            elocal_call_price:
              item.elocal_call_price !== null
                ? "$" + item.elocal_call_price
                : item.elocal_call_price,
            dialed_call_duration: formatTime(item?.dialed_call_duration),
            recording: (
              <IconButton
                component="a"
                href={item.recording_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <MicIcon />
              </IconButton>
            ),
            date: moment(createdAt).format("MM/DD/YYYY h:mm A"),
            review_status:
              item?.review_status === "finished"
                ? ""
                : item?.review_status === "rejected"
                ? "Good Call"
                : capitalizeFirstLetter(item?.review_status || ""),
            reviewer_reason:
              item?.reviewer_reason && item?.reviewer_reason !== "null"
                ? item?.reviewer_reason
                : null,
            reviewer_notes:
              item?.reviewer_notes && item?.reviewer_notes !== "null"
                ? item?.reviewer_notes
                : null,
            actions: pageName === "Home" && (
              <div className="space-x-2">
                {userSelector.is_admin || item?.review_status !== null ? (
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ paddingX: 3 }}
                    onClick={() => {
                      setPopupUuid(item.uuid);
                      setDisputePopupOpen(true);
                      setOnlyView(
                        (userSelector.is_admin &&
                          item?.review_status !== "disputed") ||
                          (!userSelector.is_admin &&
                            (item?.review_status !== "finished" ||
                              item?.review_status == null))
                          ? true
                          : false
                      );
                      setReason(
                        item?.reviewer_reason &&
                          item?.reviewer_reason !== "null"
                          ? item?.reviewer_reason
                          : null
                      );
                      setComment(
                        item?.reviewer_notes && item?.reviewer_notes !== "null"
                          ? item?.reviewer_notes
                          : null
                      );
                    }}
                  >
                    View
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setPopupUuid(item.uuid);
                      setDisputePopupOpen(true);
                    }}
                  >
                    Dispute
                  </Button>
                )}
              </div>
            ),
          };
        })
      : [];

  const columns = [
    {
      accessor: "date",
      label: "Date Time",
      center: true,
      sortable: true,
    },
    ...(userSelector.is_admin
      ? [
          {
            accessor: "buyer_name",
            label: "Buyer Name",
            center: true,
            sortable: true,
          },
        ]
      : []),
    {
      accessor: "caller",
      label: "Caller ID",
      center: true,
    },
    {
      accessor: "recording",
      label: "Recording URL",
      center: true,
      width: 190,
    },
    {
      accessor: "dialed_call_duration",
      label: "Call Duration",
      center: true,
      sortable: true,
    },
    {
      accessor: "revenue",
      label: "Conversion Amount",
      center: true,
      sortable: true,
      width: 190,
    },
    {
      accessor: "review_status",
      label: "Status",
      center: true,
      width: 150,
    },
  ];

  const totalConversionAmount = formattedData.reduce((acc, item) => {
    const revenue = parseFloat(item.revenue.replace("$", ""));
    return acc + (isNaN(revenue) ? 0 : revenue);
  }, 0);

  if (pageName === "Home") {
    columns.push({
      accessor: "actions",
      label: "Actions",
      center: true,
    });
  }

  const styles = getStyles<StylesClasses>(defaultStyles);
  const { initials, image, isLoggedIn, email } = useAppSelector(
    (state) => state.userReducer.value
  );
  const [menubar, setMenuBar] = useState(false);
  const navigate = useNavigate();

  const logout = () => {
    dispatch(clearUserData());
    localStorage.clear();
    navigate(routes.login);
  };

  return (
    <Box {...styles("wrapper")}>
      {isLoggedIn && (
        <Box {...styles("yNav")}>
          <Box {...styles("yNavItems")}>
            <Box>
              <Box>
                <Link to={"/"}>
                  <Box
                    sx={{
                      ...defaultStyles.menuItem,
                      height: "70px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      backgroundColor:
                        location.pathname === "/" ? "#0062ff" : "transparent",
                    }}
                  >
                    <Box
                      component="img"
                      src="/images/Dashboard.png"
                      sx={{
                        display: "block",
                        mx: "auto",

                        filter:
                          location.pathname === "/"
                            ? "invert(1) brightness(2)"
                            : "brightness(1)",
                      }}
                    />
                    <Typography
                      color={location.pathname === "/" ? "white" : "black"}
                      fontSize="10px"
                      sx={{ mt: "5px", textAlign: "center" }}
                    >
                      Home
                    </Typography>
                  </Box>
                </Link>
              </Box>
            </Box>
            <Box>
              <Box>
                <Link to={"/calllogs"}>
                  <Box
                    sx={{
                      ...defaultStyles.menuItem,

                      backgroundColor:
                        location.pathname === "/calllogs"
                          ? "#0062ff"
                          : "transparent",
                    }}
                  >
                    <Box
                      component="img"
                      src="/images/call-history.svg"
                      sx={{
                        display: "block",
                        mx: "auto",
                        width: "80%",
                        filter:
                          location.pathname === "/calllogs"
                            ? "invert(1) brightness(2)"
                            : "brightness(1)",
                      }}
                    />
                    <Typography
                      color={
                        location.pathname === "/calllogs" ? "white" : "black"
                      }
                      fontSize="10px"
                      sx={{ mt: "5px", textAlign: "center" }}
                    >
                      Call Logs
                    </Typography>
                  </Box>
                </Link>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      <Box {...styles("container")}>
        <Box {...styles("xNav")}>
          <Box
            sx={{
              display: isLoggedIn ? "none" : "block",
              m: "0 5px 0 -10px",
            }}
            component="img"
            src="/svg/dashboard-logo.svg"
          />
          <Box
            sx={{
              fontSize: "24px",
              fontWeight: 700,
              color: "#27292C",
            }}
          >
            {/* {heading} */}
          </Box>

          {isLoggedIn && (
            <>
              {image ? (
                <Box sx={{ mb: "40px" }} component="img" src="image" />
              ) : (
                <Box className="justify-between w-full flex-row flex">
                  <Box
                    component="img"
                    src="/images/logoNew.png"
                    className="w-32"
                  />
                  <Box className="cursor-pointer pt-1">
                    <Box
                      onClick={() => setMenuBar(!menubar)}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Box {...styles("initials")}> {initials}</Box>
                      <Typography sx={{ mx: 2, color: "black", fontSize: 16 }}>
                        {email}
                      </Typography>
                    </Box>
                    {menubar && (
                      <Box className="absolute bg-[#fff] shadow-lg z-50  right-2  rounded-md">
                        <Typography
                          sx={{
                            width: "8rem",
                            paddingX: 2,
                            paddingY: 1.5,
                            boxShadow: 5,
                            textAlign: "center",
                            borderRadius: 2,
                            "&:hover": {
                              background: "#e2e2e2",
                              cursor: "pointer",
                            },
                          }}
                          onClick={logout}
                          mt={1}
                        >
                          Logout
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Box>
              )}
            </>
          )}
        </Box>
        <Box {...styles("main")}>
          <Box className="p-3">
            <Box className="rounded-md">
              <Box className="bg-[#0062ff] p-4 rounded-t-xl">
                <Typography
                  sx={{ fontSize: "20px", fontWeight: 600, color: "#fff" }}
                >
                  {pageName}
                </Typography>
              </Box>
              {pageName === "Home" && userSelector.is_admin && (
                <Box className="bg-[#fff] p-5 ">
                  <Box mb={2} className="flex items-end space-x-4">
                    <FormControl className="w-[20%] md:w-[15vw]">
                      <InputLabel id="demo-simple-select-label" shrink={true}>
                        Buyer Name
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedBuyerName}
                        label="Buyers Name"
                        onChange={(event: SelectChangeEvent) => {
                          selectBuyerName(event.target.value as string);
                        }}
                        notched={false}
                      >
                        {!!optionList.buyer_name &&
                          optionList.buyer_name.map(
                            (item: { buyer_name: string  } ,  index: number) => (
                              <MenuItem  key={index}  value={item.buyer_name}>
                                {item.buyer_name}
                              </MenuItem>
                            )
                          )}
                      </Select>
                    </FormControl>

                    <Button
                      variant="contained"
                      className="-mt-10"
                      onClick={handleDateRangeChange}
                    >
                      Apply
                    </Button>
                  </Box>
                </Box>
              )}
              {pageName !== "Home" && (
                <Box className="bg-[#fff] p-5 ">
                  <Grid container spacing={2} mb={2}>
                    <div className="date-select" style={{ marginTop: "20px" }}>
                      <Grid container spacing={2}>
                        <Grid item sx={{ width: 180 }}>
                          <Box sx={{ width: "150px" }}>
                            <Typography
                              variant="body2"
                              sx={{ mb: 0.7, fontWeight: "500" }}
                            >
                              Start Date
                            </Typography>
                            <DatePicker
                              selected={startDate}
                              onChange={(date: Date | null) =>
                                setStartDate(date)
                              }
                              dateFormat="MM/dd/yyyy"
                              customInput={
                                <Box
                                  component="input"
                                  style={{
                                    boxShadow:
                                      "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                                  }}
                                  sx={{
                                    width: "100%",
                                    padding: "8px",
                                    border: "1px solid #ccc",
                                    borderRadius: "4px",
                                  }}
                                />
                              }
                            />
                          </Box>
                        </Grid>

                        <Grid item sx={{ width: 180 }}>
                          <Box sx={{ width: "150px" }}>
                            <Typography
                              variant="body2"
                              sx={{ mb: 0.7, fontWeight: "500" }}
                            >
                              End Date
                            </Typography>
                            <DatePicker
                              selected={endDate}
                              onChange={(date: Date | null) =>
                                handleEndDateChange(date)
                              }
                              dateFormat="MM/dd/yyyy"
                              minDate={startDate || undefined}
                              customInput={
                                <Box
                                  component="input"
                                  style={{
                                    boxShadow:
                                      "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                                  }}
                                  sx={{
                                    width: "100%",
                                    padding: "8px",
                                    border: "1px solid #ccc",
                                    borderRadius: "4px",
                                    "& .MuiOutlinedInput-input": {
                                      paddingLeft: "4px",
                                    },
                                  }}
                                />
                              }
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    </div>

                    {!!userSelector.is_admin && (
                      <Grid item sx={{ width: 225 }}>
                        <FormControl className="w-[90%]">
                          <InputLabel id="demo-simple-select-label">
                            Target Name
                          </InputLabel>
                          <Select
                            placeholder="Select Option"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedTargetName}
                            label="Target Name"
                            onChange={(event: SelectChangeEvent) => {
                              selectTargetName(event.target.value as string);
                            }}
                            notched={false}
                          >
                            {!!optionList.target_name &&
                              optionList.target_name.map(
                                (item: { target_name: string }) => (
                                  <MenuItem
                                    key={item.target_name}
                                    value={item.target_name}
                                  >
                                    {item.target_name}
                                  </MenuItem>
                                )
                              )}
                          </Select>
                        </FormControl>
                      </Grid>
                    )}

                    {!!userSelector.is_admin && (
                      <Grid item sx={{ width: 225 }}>
                        <FormControl className="w-[90%]">
                          <InputLabel id="campaign-label">
                            Campaign Name
                          </InputLabel>
                          <Select
                            labelId="campaign-label"
                            id="campaign-label"
                            value={selectedCampaignName}
                            label="campaign-label"
                            onChange={(event: SelectChangeEvent) => {
                              selectCampaignName(event.target.value as string);
                            }}
                            notched={false}
                          >
                            {!!optionList.campaign_name &&
                              optionList.campaign_name.map(
                                (item: { campaign_name: string }) => (
                                  <MenuItem
                                    key={item.campaign_name}
                                    value={item.campaign_name}
                                  >
                                    {item.campaign_name}
                                  </MenuItem>
                                )
                              )}
                          </Select>
                        </FormControl>
                      </Grid>
                    )}
                    {userSelector.is_admin && (
                      <Grid item sx={{ width: 225 }}>
                        <FormControl className="w-[90%]">
                          <InputLabel id="buyer-label">Buyer Name</InputLabel>
                          <Select
                            labelId="buyer-label"
                            id="buyer-label"
                            value={selectedBuyerName}
                            label="Buyer Name"
                            onChange={(event: SelectChangeEvent) => {
                              selectBuyerName(event.target.value as string);
                            }}
                            notched={false}
                          >
                            {!!optionList.buyer_name &&
                              optionList.buyer_name.map(
                                (item: { buyer_name: string }) => (
                                  <MenuItem
                                    key={item.buyer_name}
                                    value={item.buyer_name}
                                  >
                                    {item.buyer_name}
                                  </MenuItem>
                                )
                              )}
                          </Select>
                        </FormControl>
                      </Grid>
                    )}
                    <Grid item sx={{ width: 225 }}>
                      <FormControl className="w-[90%]">
                        <InputLabel id="demo-simple-label">
                          Caller ID
                        </InputLabel>
                        <OutlinedInput
                          label="Caller ID"
                          value={selectedCallerId}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            selectCallerId(event.target.value as string);
                          }}
                          notched={false}
                        />
                      </FormControl>
                    </Grid>

                    <Grid
                      item
                      sx={{ width: 100, alignItems: "end", display: "flex" }}
                    >
                      <Button
                        variant="contained"
                        className="mt-1"
                        onClick={handleDateRangeChange}
                      >
                        Apply
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              )}

              <Box className="bg-[#fff] min-h-[120vh] md:min-h-[75vh] p-5 rounded-b-xl">
                <Box>
                  {loading ? (
                    <div className="flex justify-center min-w-full">
                      <CircularProgress size={50} />
                    </div>
                  ) : error ? (
                    <p>{error}</p>
                  ) : (
                    <>
                      <NewTable
                        data={formattedData || []}
                        columns={columns}
                        sortConfig={sortConfig}
                        onSortChange={handleSortChange}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          mt: 2,
                          px: 2,
                        }}
                      >
                        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                          Total Conversion Amount:
                        </Typography>
                        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                          ${totalConversionAmount.toFixed(2)}{" "}
                        </Typography>
                      </Box>
                      <Pagination
                        count={totalPages}
                        page={pageNumber}
                        onChange={handlePageChange}
                        color="primary"
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          mt: 2,
                        }}
                      />
                    </>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
          <DisputePopupModal
            isOpen={isDisputePopupOpen}
            handleCancellation={handlePopupClose}
            dialogTitle={"Dispute Information"}
            isAdmin={userSelector.is_admin}
            handleApprove={handleApprove}
            handleDispute={handleDispute}
            handleDeny={handleDeny}
            reason={reason}
            setReason={setReason}
            comment={comment}
            setComment={setComment}
            popupUuid={popupUuid}
            setPopupUuid={setPopupUuid}
            loading={loading}
            onlyView={onlyView}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Home;
