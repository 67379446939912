import { jwtDecode } from "jwt-decode";
import { updateToken } from "../store/slices/auth-slice";
import { store } from "../store/store";
import { LocalStorageService } from "./localstorageservice";
import { HttpService } from "./httpservice";

export class UserService {
    public static SaveAccessToken(access_token: string) {
        LocalStorageService.Set("userToken", access_token);
        store.dispatch(updateToken(access_token));

    }

    public static GetAccessToken(): string | null {
        let access_token_state: string = store.getState().authReducer.value.token;
        if (access_token_state) {
            return access_token_state
        }

        let access_token_sorage = LocalStorageService.Get<string>("userToken")
        if (access_token_sorage) {
            store.dispatch(updateToken(access_token_sorage));
            LocalStorageService.Set("userDetail", jwtDecode(access_token_sorage));


        }

        return access_token_sorage;
    }

    public static async autoJoinHandler(autoJoin: number) {
        return await HttpService.CoreApiPost("users/update", {
            auto_join: autoJoin
        });
    }

    public static async changeAssistantName(botName: string) {
        return await HttpService.CoreApiPost("users/updateBotName", {
            botName
        })
    }

    public static async getAssistantName() {
        return await HttpService.CoreApiGet("users/getBotName")
    }
}