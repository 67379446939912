import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import { AuthLayout } from "layout";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { forgetPassword } from "types/form.types";
import routes from "router/routes";
import Input from "components/form/Input";
import Button from "components/common/Button";
import { HttpService } from "services/httpservice";
import { toast } from "react-toastify";

const ForgetPassword = (props: any) => {
  const { handleSubmit, control } = useForm<forgetPassword>({
    mode: "onTouched",
  });
  const [loader, setLoader] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [disabled, setDisabled] = useState<boolean>(false);

  const handleForgetPassword = async (email: string) => {
    try {
      setLoader(true);
      const response = await HttpService.CoreApiPost("forgot", { email });

      if (response.status >= 200 && response.status < 300) {
        console.log("Password reset successful:", response.data);
        // navigate(routes.recovery, {
        //   state: { email: email },
        // });
        toast.success("We have sent a recovery link to the provided email");
        setError(null);
        setDisabled(true);
      } else if (response.status === 400) {
        setError("Invalid request. Please check your email format.");
      } else {
        setError(
          `Error during password reset. Server response: ${response.status}`
        );
      }
    } catch (error) {
      setError("We are unable to find an account for that email address.");
      console.error("Unexpected error during password reset:", error);
    } finally {
      setLoader(false);
    }
  };

  return (
    <AuthLayout
      image="/images/auth-cover.jpg"
      design="absolute right-0 w-[100%] bottom-0"
    >
      <Box className="shadow-xl  min-h-[90vh] p-4 mb-3 bg-white">
        <Box className="flex flex-col justify-center items-center gap-2 p-4">
          <Box className="flex flex-col justify-center items-center">
            <h2 className="text-[41px] font-bold text-center">
              Forgot Password
            </h2>
            <p className="text-[14px] mt-8 text-center">
              Please enter your email address for which you have forgotten the
              password.
            </p>
          </Box>
          <form
            onSubmit={handleSubmit((data) => {
              handleForgetPassword(data.email);
            })}
            className="mt-4 flex flex-col justify-center items-center self-center w-full"
          >
            <Box className="w-[80%] md:w-[60%]">
              <Input
                label="Email"
                name="email"
                control={control}
                rules={{ required: "Email is required" }}
              />
            </Box>

            <Button
              label="Send Recovery Link"
              type="submit"
              loading={loader}
              className=""
              disabled={disabled}
              sx={{
                mt: 5,
                minWidth: {
                  xs: "30%",
                  md: "60%",
                },
                fontSize: {
                  xs: "17px",
                  md: "20px",
                },
              }}
            />
          </form>
          <Box className="flex justify-center items-center text-[16px] mt-2 w-[60%] ">
            {error && <p style={{ color: "red" }}>{error}</p>}
          </Box>
          <Box className="flex justify-center">
            <p className="mt-5 text-[18px]">
              <Link className="text-blue-500" to={routes.login}>
                Return
              </Link>{" "}
              to log in{" "}
            </p>
          </Box>
        </Box>
      </Box>
    </AuthLayout>
  );
};

export default ForgetPassword;
