import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "store/store";

interface UserSlice {
  initials: string;
  image: string;
  isLoggedIn: boolean;
  auto_join: boolean;
  users_step: boolean;
  concate_step: boolean;
  continousChat: boolean;
  email: string;
  is_admin: boolean;
}

interface UserState {
  value: UserSlice;
}

const initialStateValue: UserSlice = {
  initials: "",
  image: "",
  isLoggedIn: false,
  auto_join: true,
  users_step: false,
  concate_step: false,
  continousChat: false,
  is_admin: false,

  email: "",
};

const initialState: UserState = {
  value: initialStateValue,
};

export const userSlice = createSlice({
  name: "UserState",
  initialState,
  reducers: {
    clearUserData: (state) => {
      state.value = initialStateValue;
    },
    updatedUserData: (state, action: PayloadAction<Partial<UserSlice>>) => {
      state.value = { ...state.value, ...action.payload };
      state.value.isLoggedIn = true;
    },
    users_step_status: (state, action: PayloadAction<boolean>) => {
      state.value = { ...state.value, users_step: action.payload };
    },
    concate_status: (state, action: PayloadAction<boolean>) => {
      state.value = { ...state.value, concate_step: action.payload };
    },
    continous_status: (state, action: PayloadAction<boolean>) => {
      state.value = { ...state.value, continousChat: action.payload };
    },
  },
});

export const {
  clearUserData,
  updatedUserData,
  users_step_status,
  concate_status,
  continous_status,
} = userSlice.actions;

export const selectUser = (state: RootState) => state.userReducer.value;
export default userSlice.reducer;
