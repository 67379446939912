const routes = {
  home: "/",
  callHistory: "/calllogs",
  settings: "/settings",
  login: "/login",
  signUp: "/sign-up",
  verifyEmail: "/verify-email/:emailId",
  emailVerification: "/email-verification/:UUID",
  forgetPassword: "/forgetPassword",
  changePasswrd: "/reset-password/:UUID",
  password: "/changePassword",
};

export default routes;
