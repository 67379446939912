import { Styles } from "types/common.types";

const styles: Styles<StylesClasses> = {
  wrapper: {
    background: "white",
    width: "350px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    boxShadow: 24,
    p: "20px 30px",
    borderRadius: "8px",
  },
  closeIcon: {
    position: "absolute",
    top: 5,
    right: 5,
  },
  title: { fontSize: "22px", fontWeight: 600 },
  description: { fontSize: "14px", p: "10px 0 20px 0" },
};

export type StylesClasses = "wrapper" | "closeIcon" | "title" | "description";

export default styles;
