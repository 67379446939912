import { FieldErrors } from "react-hook-form";

import { Styles, SxProps } from "types/common.types";

export const getError = (name: string, errors: FieldErrors) => {
  if (!Object.keys(errors).length) return undefined;

  return name
    .split(".")
    .map((item) => (item as any).replaceAll("[", "").replaceAll("]", ""))
    .reduce((prev, curr) => (prev ? prev[curr] : prev), errors as any);
};

export const getStyles = <T extends string = string>(
  mainStyles: Styles,
  customStyles?: Styles
) => {
  return (names: T | T[], sx?: SxProps): { sx: SxProps } => {
    let ms: SxProps = {},
      cs: SxProps = {},
      es = sx ?? {};

    if (Array.isArray(names)) {
      ms = {};
      cs = {};

      names.forEach((name) => {
        ms = {
          ...ms,
          ...mainStyles[name],
        } as SxProps;

        const newCs = customStyles?.[name];
        if (newCs)
          cs = {
            ...cs,
            ...newCs,
          } as SxProps;
      });
    } else {
      ms = mainStyles[names];
      cs = customStyles?.[names] ?? {};
    }

    const resultantStyles = { ...ms, ...es, ...cs } as SxProps;

    return { sx: resultantStyles };
  };
};
