import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Button from "components/common/Button";
import UserForm from "components/feature/UserForm";
import { AuthLayout } from "layout";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { updatedUserData } from "store/slices/user-slice";
import { useAppDispatch } from "store/store-hooks";
import { UserDetailsForm } from "types/form.types";
import { NetworkResponse } from "types/network.types";
import { AuthService } from "../../services/authservice";
import { UserService } from "../../services/userservice";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import routes from "router/routes";

export const LoginPage = (props: any) => {
  const navigate = useNavigate();
  const [err, setErr] = useState("");
  const { handleSubmit, control } = useForm<UserDetailsForm>({
    defaultValues: {
      email: "",
      password: "",
    },
    mode: "onTouched",
  });

  const dispatch = useAppDispatch();
  const [loader, setLoader] = useState<boolean>(false);

  const LoginHandler = async (login: UserDetailsForm) => {
    try {
      setLoader(true);
      const {
        data,
      }: NetworkResponse<{
        token: string;
        email: string;
        auto_join: number;
        is_admin: boolean;
      }> = await AuthService.login(login.email, login.password);
      setLoader(false);

      UserService.SaveAccessToken(data.body.token);

      dispatch(
        updatedUserData({
          initials: data.body.email.slice(0, 2),
          auto_join: data.body.auto_join !== 0,
          email: data.body.email,
          is_admin: data?.body?.is_admin,
        })
      );
      window.location.reload();
    } catch (error: any) {
      setLoader(false);
      // setError("error", { message: error?.response?.data?.message });
      setErr(error?.response?.data?.message);
    }
  };

  return (
    <AuthLayout
      image="/images/auth-cover.jpg"
      design="absolute right-0 w-[100%] bottom-0"
      // design="absolute top-48 bottom-0 left-0 mt-[90px] "
    >
      <Box className="shadow-xl  min-h-[80vh]  md:min-h-[85vh] lg:min-h-[90vh] p-1 md:p-3 mb-3 bg-white rounded-sm">
        <div className="flex justify-center pt-5 pb-5 items-center gap-2">
          <img
            src="/images/logoNew.png"
            className="w-[180px]"
            alt="consumer-ave-logo"
          />
        </div>
        <Box className="flex flex-col items-center justify-center">
          <form
            onSubmit={handleSubmit((data) => {
              LoginHandler(data);
            })}
            className="mt-5 flex flex-col justify-center text-center items-center w-full"
          >
            <Box className="w-full flex justify-center items-center">
              <UserForm control={control} />
            </Box>

            <Box
              sx={{
                fontSize: "16px",
                mt: "20px",
                fontWeight: 600,
                cursor: "pointer",
                textDecoration: "underline",
              }}
              onClick={() => navigate(routes.forgetPassword)}
            >
              Forgot Password
            </Box>

            <Button
              label="Sign in"
              type="submit"
              loading={loader}
              sx={{
                mt: 5,
                minWidth: {
                  xs: "40%",
                  md: "50%",
                },
                fontSize: {
                  xs: "18px",
                  md: "20px",
                },
              }}
            />
          </form>
          <Box>
            {err ? (
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  alignItems: "center",
                  color: "red",
                  mt: 2,
                }}
              >
                <ErrorOutlineIcon sx={{ color: "red" }} />
                {err}
              </Box>
            ) : (
              ""
            )}
          </Box>
        </Box>
      </Box>
    </AuthLayout>
  );
};
