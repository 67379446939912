import { Box } from "@mui/material";
import { DashboardLayout } from "layout";
import { ReactNode, useState } from "react";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import styles from "./styles";
import { useNavigate } from "react-router-dom";

import "./styles.css";

type SettingsProps = {
  children: ReactNode;
};

function Settings({ children }: SettingsProps) {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);

  const getStyling = (tab: number) => {
    const Class = activeTab === tab ? styles.active : {};
    return {
      ...Class,
      ...styles.item,
    };
  };

  const redirect = (tab: number, route: string) => {
    navigate(`/settings/${route}`);
    setActiveTab(tab);
  };

  return (
    <DashboardLayout>
      <Box sx={styles.container}>
        <Box sx={styles.left}>
          <Box sx={{ mb: "40px" }} className="settings_heading">
            Settings
          </Box>

          <Box onClick={() => redirect(0, "profile")} sx={getStyling(0)}>
            <AccountCircleOutlinedIcon />
            <Box sx={styles.itemText}>Profile</Box>
          </Box>
        </Box>

        <Box sx={styles.right}>{children}</Box>
      </Box>
    </DashboardLayout>
  );
}

export default Settings;
