import CancelIcon from "@mui/icons-material/Cancel";
import { Box, IconButton } from "@mui/material";
import Modal from "@mui/material/Modal";
import Button from "components/common/Button";
import { ReactNode } from "react";
import { CustomStyles } from "types/common.types";
import { getStyles } from "utils/common";
import defaultStyles, { StylesClasses } from "./styles";

type DefaultModalProps = {
  children?: ReactNode;
  onSubmit: () => void;
  onClose: () => void;
  customStyles?: CustomStyles<StylesClasses>;
  isOpen: boolean;
  content: {
    title: string;
    description: string;
    submitLabel: string;
    cancelLabel? : string
  };
};

const DefaultModal = ({
  children,
  content,
  onClose,
  onSubmit,
  isOpen,
  customStyles,
}: DefaultModalProps) => {
  const styles = getStyles<StylesClasses>(defaultStyles, customStyles);

  return (
    <Modal open={isOpen} onClose={onClose} sx={{ background: "transparent" }}>
      <Box {...styles("wrapper")}>
        <IconButton onClick={onClose} {...styles("closeIcon")}>
          <CancelIcon />
        </IconButton>
        <>
          {children ? (
            children
          ) : (
            <>
              <Box {...styles("title")}>{content.title}</Box>
              <Box {...styles("description")}>{content.description}</Box>
             <Box className="flex gap-2">
             <Button label={content.submitLabel} onClick={onSubmit} />
              {content.cancelLabel ? <Button label={content.cancelLabel || ""} onClick={onClose} /> : null}
             </Box>
            </>
          )}
        </>
      </Box>
    </Modal>
  );
};

export default DefaultModal;
