import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import {
  FormControl,
  FormHelperText,
  InputAdornment,
  InputBase,
  InputBaseProps,
  InputLabel,
} from "@mui/material";
import React from "react";
import { Controller, FieldValues } from "react-hook-form";
import { getError } from "utils/common";
import { BaseFormElementProps } from "types/form.types";

type InputProps<T extends FieldValues> = BaseFormElementProps<T> &
  InputBaseProps;

const Input = <T extends FieldValues>({
  image,
  name,
  control,
  label,
  type = "text",
  rules,
  placeholder,
  rows = 3,
  icon,
  endicon,
  ...rest
}: InputProps<T>) => {
  return control ? (
    <Controller
      render={({ field, formState: { errors } }) => {
        const error = getError(name, errors);
        return (
          <FormControl fullWidth>
            {label && (
              <InputLabel
                sx={{
                  fontSize: "18px",
                }}
                shrink={false}
                htmlFor={`input-${name}`}
              >
                {label} {image}
              </InputLabel>
            )}

            <InputBase
              startAdornment={
                <InputAdornment position="start">{icon}</InputAdornment>
              }
              type={type}
              placeholder={placeholder}
              error={error}
              endAdornment={
                error ? <ReportProblemIcon color="error" /> : endicon && endicon
              }
              rows={rows}
              {...field}
              {...rest}
            />
            {error && <FormHelperText>{error.message}</FormHelperText>}
          </FormControl>
        );
      }}
      name={name}
      control={control || undefined}
      rules={rules}
      {...rest}
    />
  ) : (
    <FormControl fullWidth>
      {label && (
        <InputLabel
          sx={{
            fontSize: "18px",
          }}
          shrink={false}
          htmlFor={`input-${name}`}
        >
          {label} {image}
        </InputLabel>
      )}

      <InputBase
        startAdornment={
          <InputAdornment position="start">{icon}</InputAdornment>
        }
        type={type}
        placeholder={placeholder}
        rows={rows}
        {...rest}
      />
    </FormControl>
  );
};

export default Input;
