const ParseMeetingCoachData = (data: string) => {
  if (data) {
    // Extracting the HTML code
    const htmlRegex = /<dl>[\s\S]*?<\/dl>/;
    const htmlMatch = data.match(htmlRegex);
    const htmlString = htmlMatch ? htmlMatch[0] : "";

    // Extracting the array
    // const arrayRegex = /\[\s*['"]?\s*(.*?)\s*['"]?\s*,\s*(\d+)\s*]/g;
    const arrayRegex = /\[\s*['"]?\s*(.*?)\s*['"]?\s*,\s*(\d*\.?\d+)\s*]/g;

    let array = [];
    let match;
    while ((match = arrayRegex.exec(data)) !== null) {
      const name = match[1];
      const number = parseInt(match[2], 10);
      array.push([name, number]);
    }

    // Logging the results
    console.log("HTML string setParticipationLevelHtml:", htmlString);
    console.log("Array setParticipationLevelChart:", array);
    return {
      htmlString,
      array: [["Task", "Hours per Day"], ...array],
    };
  }
  return {
    htmlString: "",
    array: [],
  };
};

const parseFinalSummaryAction = (str: string) => {
  try {
    // Create an array to store extracted task details
    // var extractedDetails: any = [];

    // // Regular expression to match the "Tasks coming out of meeting" section
    // var tasksRegex = /<h2>Tasks coming out of meeting<\/h2>(.*?)<\/ul>/s;

    // // Match the "Tasks coming out of meeting" section
    // var tasksMatch = str.match(tasksRegex);

    // // Extract tasks if the match is found
    // if (tasksMatch && tasksMatch[1]) {
    //   // Extract content inside the <ul> tags
    //   var ulContent = tasksMatch[1];

    //   // Regular expression to match list items inside <ul>
    //   var liRegex = /<li>.*?:\s*(.*?)<\/li>/g;

    //   // Match all occurrences of list items
    //   var liMatches = ulContent.matchAll(liRegex);

    //   // Loop through the matches and extract details
    //   for (const match of liMatches) {
    //     var [, details] = match;
    //     extractedDetails.push(details.trim());
    //   }
    // }
    var extractedDetails: any = [];

    // Regular expression to match the "Tasks coming out of meeting" section
    var tasksRegex = /<h2>Tasks coming out of meeting<\/h2>(.*?)<\/ul>/;

    // Match the "Tasks coming out of meeting" section
    var tasksMatch = str.match(tasksRegex);

    // Extract tasks if the match is found
    if (tasksMatch && tasksMatch[1]) {
      // Extract content inside the <ul> tags
      var ulContent = tasksMatch[1];

      // Regular expression to match list items inside <ul>
      var liRegex = /<li>(?:'([^']*)'|([^']*?))\s*:\s*(.*?)<\/li>/g;

      // Match all occurrences of list items
      var liMatches = ulContent.matchAll(liRegex);

      // Loop through the matches and extract names and details
      for (const match of liMatches) {
        var [, nameWithQuotes, nameWithoutQuotes, details] = match;
        var name = nameWithQuotes || nameWithoutQuotes || ""; // Use an empty string if no name is found
        extractedDetails.push({ name: name.trim(), details: details.trim() });
      }
    }

    // Display the extracted details
    console.log(extractedDetails);
    // const yAxis = 145.97499084472656
    const yAxis = 930.44459969411787;

    const arr = extractedDetails;
    var temp: any = [
      {
        id: "6pc9vreq86oiPIo4TrO9S",
        type: "text",
        x: 120,
        y: yAxis - 50 * 1,
        width: 183.0639190673828,
        height: 35,
        angle: 0,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        fillStyle: "hachure",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        groupIds: [],
        frameId: null,
        roundness: null,
        seed: 1584433546,
        version: 39,
        versionNonce: 1945316298,
        isDeleted: false,
        boundElements: null,
        updated: 1702486807778,
        link: null,
        locked: false,
        text: "Action Items:",
        fontSize: 28,
        fontFamily: 1,
        textAlign: "left",
        verticalAlign: "top",
        baseline: 24,
        containerId: null,
        originalText: "Action Items:",
        lineHeight: 1.25,
        customElementtitle: true,
      },
    ];

    const canvas: any = document.createElement("canvas");
    const context: any = canvas.getContext("2d");
    context.font = "20px Arial"; // Set the desired font and size
    arr.forEach((item: any, index: any) => {
      const textWidth = context.measureText(`.${item.details}`).width * 2;
      var yAxisvar = yAxis + 25 * index;
      temp.push({
        id: `HOyZEGEjdC3sYWXfjPctd${index}`,
        type: "text",
        x: 120,
        y: yAxisvar,
        width: textWidth,
        height: 25,
        angle: 0,
        strokeColor: "#1e1e1e",
        backgroundColor: "transparent",
        fillStyle: "hachure",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        groupIds: [],
        frameId: null,
        roundness: null,
        seed: 1506460901,
        version: 33,
        versionNonce: 1484962795,
        isDeleted: false,
        boundElements: null,
        updated: 1702486230767,
        link: null,
        locked: false,
        text: `.${item.name}: ${item.details}`,
        fontSize: 20,
        fontFamily: 1,
        textAlign: "left",
        verticalAlign: "top",
        baseline: 18,
        containerId: null,
        originalText: `.${item.name}: ${item.details}`,
        lineHeight: 1.25,
        customElementtitle: true,
      });
    });
    return temp;
    // return extractedDetails
  } catch (error) {
    console.log("error parseFinalSummaryAction", error);
  }
};
function capitalizeFirstLetter(string: any) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export {
  ParseMeetingCoachData,
  parseFinalSummaryAction,
  capitalizeFirstLetter,
};
