import React from "react";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { Box, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Button from "components/common/Button";
import useTimer from "hooks/useTimer";
import { HttpService } from "services/httpservice";
import { emailVerificationStyles as styles } from "styles/pages.style";

export const VerifyEmail = () => {
  const { emailId } = useParams<{ emailId: string }>();
  const navigate = useNavigate();
  const [resultTime, reset, isTimedOut] = useTimer(30);

  const resendEmail = () => {
    HttpService.CoreApiPost("users/resendVerificationEmail", { email: emailId })
      .then((response) => {
        console.log(response, "popup set response");
        reset();
      })
      .catch((error) => {
        console.log({ error });
      });
  };

  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.backBtn} onClick={() => navigate(-1)}>
        <NavigateBeforeIcon /> Back
      </Box>
      <Box sx={styles.main}>
        <Box sx={styles.heading}>
          We have sent a verification to your email.{" "}
        </Box>
        <Typography component="p" sx={{ ...styles.resend, mb: "20px" }}>
          Please check your inbox including spam folder
        </Typography>
        <Box sx={styles.email}>Email Address: {emailId}</Box>
        <Box sx={styles.resend}>
          {resultTime.sec ? (
            <>
              You can resend email after <b>{resultTime.sec} seconds</b>
            </>
          ) : (
            <>You can resend verification email now.</>
          )}
        </Box>
        <Button
          disabled={!isTimedOut}
          label="Resend Email"
          severity="secondary"
          onClick={resendEmail}
        />
      </Box>
      <Box sx={styles.bgImg} component="img" src="/images/theme-image.png" />
    </Box>
  );
};
