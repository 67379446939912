import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

interface AuthSlice {
  token: string;
  tokenExpiration: Date | null;
}

interface AuthState {
  value: AuthSlice
}

const initialStateValue: AuthSlice = {
  token: '',
  tokenExpiration: null

}

const initialState: AuthState = {
  value: initialStateValue,
}

export const authSlice = createSlice({
  name: 'AuthState',
  initialState,
  reducers: {
    clear: (state) => {
      state.value = initialStateValue
    },
    update: (state, action: PayloadAction<AuthSlice>) => {
      state.value = action.payload
    },

    updateToken: (state, action: PayloadAction<string>) => {
      state.value.token = action.payload
    },
    updateExpiry: (state, action: PayloadAction<Date>) => {
      state.value.tokenExpiration = action.payload
    },
  },
})

export const {
  clear,
  updateToken,
  updateExpiry
} = authSlice.actions


export default authSlice.reducer