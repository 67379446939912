import { Box, Typography } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import DefaultModal from "components/common/DefaultModal";
import { ReactNode, useState } from "react";
import routes from "router/routes";
import { useAppDispatch, useAppSelector } from "store/store-hooks";
import { CustomStyles } from "types/common.types";
import { getStyles } from "utils/common";
import defaultStyles, { StylesClasses } from "./styles";
import { clearUserData } from "store/slices/user-slice";

type DashboardLayoutProps = {
  children: ReactNode;
  navigationBar?: ReactNode;
  customStyles?: CustomStyles<StylesClasses>;
  heading?: string;
};

const DashboardLayout = ({
  children,
  navigationBar,
  customStyles,
  heading,
}: DashboardLayoutProps) => {
  const styles = getStyles<StylesClasses>(defaultStyles, customStyles);
  const { initials, image, isLoggedIn, email } = useAppSelector(
    (state) => state.userReducer.value
  );
  const [menubar, setMenuBar] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const logout = () => {
    dispatch(clearUserData());
    localStorage.clear();
    navigate(routes.login);
  };

  return (
    <Box {...styles("wrapper")}>
      {isLoggedIn && (
        <Box {...styles("yNav")}>
          <Box {...styles("yNavItems")}>
            <Box>
              <Box>
                <Menu
                  name="Home"
                  imgSrc="/images/Dashboard.png"
                  route={routes.home}
                />
              </Box>
            </Box>
            <Box>
              <Box>
                <Menu
                  name="Call Logs"
                  imgSrc="/images/call-history.svg"
                  route={routes.callHistory}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      <Box {...styles("container")}>
        <Box {...styles("xNav")}>
          <Box
            sx={{
              display: isLoggedIn ? "none" : "block",
              m: "0 5px 0 -10px",
            }}
            component="img"
            src="/svg/dashboard-logo.svg"
          />
          <Box
            sx={{
              fontSize: "24px",
              fontWeight: 700,
              color: "#27292C",
            }}
          >
            {/* {heading} */}
          </Box>
          <Box {...styles("xNavItems")}>{navigationBar}</Box>

          {isLoggedIn && (
            <>
              {image ? (
                <Box sx={{ mb: "40px" }} component="img" src="image" />
              ) : (
                <Box className="justify-between w-full flex-row flex">
                  <Box
                    component="img"
                    src="/images/logoNew.png"
                    className="w-32"
                  />
                  <Box className="cursor-pointer pt-1">
                    <Box
                      onClick={() => setMenuBar(!menubar)}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Box {...styles("initials")}> {initials}</Box>
                      <Typography sx={{ mx: 2, color: "black", fontSize: 16 }}>
                        {email}
                      </Typography>
                    </Box>
                    {menubar && (
                      <Box className="absolute bg-[#fff] shadow-lg z-50  right-2  rounded-md">
                        <Typography
                          sx={{
                            width: "8rem",
                            paddingX: 2,
                            paddingY: 1.5,
                            boxShadow: 5,
                            textAlign: "center",
                            borderRadius: 2,
                            "&:hover": {
                              background: "#e2e2e2",
                              cursor: "pointer",
                            },
                          }}
                          onClick={logout}
                          mt={1}
                        >
                          Logout
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Box>
              )}
            </>
          )}
        </Box>
        <Box {...styles("main")}>{children}</Box>
      </Box>
    </Box>
  );
};

const Menu = ({
  imgSrc,
  route,
  name,
}: {
  imgSrc: string;
  route: string;
  name: string;
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const onClose = () => setOpen(false);

  return (
    <>
      <Box
        sx={{
          backgroundColor: location.pathname === route ? "#0062ff" : "none",
          padding: "8px",
          borderRadius: "12px",
        }}
      >
        <Box
          component="img"
          src={imgSrc}
          onClick={() => {
            navigate(route);
          }}
          sx={{
            borderRadius: "0px",
            ...defaultStyles.menuItem,
            display: "block",
            mx: "auto",
            border: location.pathname === route ? "0px solid" : "0px",
            margin: "0 auto",
            filter:
              location.pathname === route
                ? "invert(1) brightness(2)"
                : "brightness(1)",
          }}
        />
        <Typography
          color={location.pathname === route ? "white" : "black"}
          fontSize="10px"
          sx={{
            mt: "5px",
            textAlign: "center",
          }}
        >
          {name}
        </Typography>
      </Box>
      <DefaultModal
        isOpen={open}
        onClose={onClose}
        onSubmit={() => navigate(route)}
        content={{
          title: "Session in progress",
          description: "Are you sure you want to leave?",
          submitLabel: "Yes",
          cancelLabel: "No",
        }}
      />
    </>
  );
};

export default DashboardLayout;
