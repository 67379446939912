import {
  Button as MatButton,
  ButtonProps as MatButtonProps,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import React from "react";
import { Styles } from "types/common.types";

type ButtonProps = {
  label: string;
  severity?: "primary" | "secondary";
  loading?: boolean;
  inverse?: boolean;
} & MatButtonProps;

const Button = ({
  label,
  severity = "primary",
  loading = false,
  inverse = false,
  sx,
  ...rest
}: ButtonProps) => {
  return (
    <MatButton
      variant="contained"
      sx={Object.assign(
        { ...styles.main },
        {
          ...(severity === "secondary" &&
            styles[inverse ? "secondaryInverse" : "secondary"]),
        },
        sx
      )}
      endIcon={
        loading && (
          <CircularProgress
            size="15px"
            sx={{
              color:
                severity === "primary" ? "white" : inverse ? "white" : "black",
            }}
          />
        )
      }
      {...rest}
    >
      {label}
    </MatButton>
  );
};

const styles: Styles<"main" | "secondary" | "secondaryInverse"> = {
  main: {
    width: "max-content",
    padding: "12px 16px",
  },
  secondary: {
    background: "white",
    color: "black",
    "&:hover": {
      background: "white",
    },
    "&.Mui-disabled": {
      background: "black",
      color: "white",
    },
  },
  secondaryInverse: {
    background: "black",
    color: "white",
    "&:hover": {
      background: "black",
    },
    "&.Mui-disabled": {
      background: "white",
      color: "black",
    },
  },
};

export default Button;
