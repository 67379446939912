import { Box } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import Button from "components/common/Button";
import useTimer from "hooks/useTimer";
import React, { useEffect, useState } from "react";
import { emailVerificationStyles as styles } from "styles/pages.style";
import { NetworkError, NetworkResponse } from "types/network.types";
import { AuthService } from "../../services/authservice";
import routes from "router/routes";

export const EmailVerification = () => {
  const { UUID } = useParams<{ UUID: string }>();
  const [resultTime, reset, isTimedOut] = useTimer(30, true);
  const [notValid, setNotValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const { mutate, isSuccess, isError } = useMutation({
    mutationFn: ({ UUID }: { UUID: string }) => AuthService.verify(UUID),
    onSuccess: (_res: NetworkResponse) => {
      reset(5);
      setLoading(false);
      console.log("Verified Successfully");
    },
    onError: ({ response }: NetworkError) => {
      setLoading(false);
      console.log(response?.data.message);
    },
  });

  useEffect(() => {
    if (UUID) {
      setLoading(true);
      mutate({ UUID });
    } else {
      setNotValid(true);
    }
  }, [UUID, mutate]);

  useEffect(() => {
    if (isTimedOut && isSuccess) navigate(routes.login);
  }, [isTimedOut, isSuccess, navigate]);

  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.main}>
        {loading && <Box sx={styles.heading}>Verifying...</Box>}
        {notValid && (
          <Box sx={styles.heading}>Not a valid verification link</Box>
        )}
        {isSuccess && (
          <>
            <Box sx={styles.heading}>Email has been Successfully Verified</Box>
            <Box sx={styles.email}>
              Redirecting to login page in <b>{resultTime.sec} seconds</b>
            </Box>
            <Button
              label="Go to Sign In"
              severity="secondary"
              onClick={() => navigate(routes.login)}
            />
          </>
        )}
        {isError && (
          <>
            <Box sx={styles.heading}>Verification link has been expired</Box>
            <Box sx={styles.resend}>
              {resultTime.sec ? (
                <>
                  You can resend email after <b>{resultTime.sec} seconds</b>
                </>
              ) : (
                <>You can resend verification email now.</>
              )}
            </Box>
            <Button
              disabled={!isTimedOut}
              label="Resend Email"
              severity="secondary"
              onClick={() => reset()}
            />
          </>
        )}
      </Box>
      <Box sx={styles.bgImg} component="img" src="/images/theme-image.png" />
    </Box>
  );
};
