import { Box } from '@mui/material';


function Profile() {
  return (
      <Box>
        <h2 className="settings_heading">Profile</h2>
        <div className="settings_line"></div>

        <h3 className='settings_sub-heading'>Profile Details</h3>

       

        <Box sx={{mt: '20px', mb: '5px'}}>
          First Name
        </Box>

        <input className='input' type="text" />

        <Box sx={{mt: '15px', mb: '5px'}}>
          Last Name
        </Box>

        <input className='input' type="text" />
        <br />

        {/* <Button className="btn-black" sx={{mt: '40px'}}>Save</Button> */}
      </Box>
  )
}

export default Profile