import React from "react";
import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
} from "@mui/material";

interface Column {
  label?: string;
  accessor?: string;
  field?: string;
  width?: string | number;
  center?: boolean;
  sortable?: boolean;
  cell?: (row: any) => React.ReactNode;
}

interface NewTableProps {
  columns: Column[];
  data: any[];
  sortConfig: { key: string; direction: "asc" | "desc" };
  onSortChange: (sortKey: string) => void;
  tableWidth?: string | number;
}

const NewTable: React.FC<NewTableProps> = ({
  columns,
  data,
  sortConfig,
  onSortChange,
  tableWidth,
}) => {
  return (
    <TableContainer
      component={Paper}
      style={{ width: tableWidth || "100%" }}
      sx={{
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", // Light box shadow
        borderRadius: "4px", // Optional: to add rounded corners
      }}
    >
      <MuiTable>
        <TableHead>
          <TableRow>
            {columns.map((column, index) => (
              <TableCell
                key={index}
                style={{
                  width: column.width,
                  fontSize: 14,
                  fontWeight: "bold",
                  textAlign: column.center ? "center" : "left",
                  cursor: column.sortable ? "pointer" : "default",
                  paddingLeft: column.sortable ? 5 : 0, // Horizontal padding left
                  paddingRight: column.sortable ? 5 : 0, // Horizontal padding right
                }}
              >
                {column.sortable ? (
                  <TableSortLabel
                    active={
                      sortConfig.key === (column.accessor || column.field)
                    }
                    direction={
                      sortConfig.key === (column.accessor || column.field)
                        ? sortConfig.direction
                        : "asc"
                    }
                    onClick={() =>
                      onSortChange(column.accessor || column.field || "")
                    }
                    style={{
                      marginLeft: 20,
                    }}
                    sx={{
                      "& .MuiTableSortLabel-icon": {
                        visibility: "visible", // Always show the sorting icon
                        opacity: 0.1, // Slightly transparent
                        transition: "opacity 0.3s", // Smooth transition
                      },
                      "&:hover .MuiTableSortLabel-icon": {
                        opacity: 1, // Full opacity on hover
                      },
                    }}
                  >
                    {column.label || column.accessor || column.field}
                  </TableSortLabel>
                ) : (
                  column.label || column.accessor || column.field
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, rowIndex) => (
            <TableRow
              key={rowIndex}
              className={rowIndex % 2 === 0 ? "even" : "odd"}
              style={{
                backgroundColor: rowIndex % 2 === 0 ? "#F2F9FF" : "#ffffff", // Zebra striping
              }}
            >
              {columns.map((column, colIndex) => (
                <TableCell
                  key={colIndex}
                  style={{
                    fontSize: 12,
                    width: column.width,
                    textAlign: column.center ? "center" : "left",
                  }}
                  className={column.center ? "text-center" : ""}
                >
                  {column.cell ? (
                    column.cell(row)
                  ) : (
                    <>
                      {column.accessor && row[column.accessor] !== undefined
                        ? row[column.accessor]
                        : column.field && row[column.field] !== undefined
                        ? row[column.field]
                        : null}
                    </>
                  )}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </MuiTable>
    </TableContainer>
  );
};

export default NewTable;
