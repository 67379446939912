import {
  Box,
  IconButton,
  InputAdornment,
  Snackbar,
  Typography,
} from "@mui/material";
import { DashboardLayout } from "layout";
import LockIcon from "@mui/icons-material/Lock";
import Input from "components/form/Input";
import Button from "components/common/Button";
import { changeUserPassword } from "types/form.types";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { HttpService } from "services/httpservice";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import { UserService } from "services/userservice";

const UserProfile = () => {
  const [response, setResponse] = useState("");
  const [success, setSuccess] = useState(false);
  const [err, setErr] = useState(false);
  const [loader, setLoader] = useState<boolean>(false);
  const { handleSubmit, control, reset } = useForm<changeUserPassword>({
    defaultValues: {
      currPassword: "",
      newPassword: "",
      assistantName: "",
    },
    mode: "onTouched",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);

  const changePasswordHandler = async (obj: any) => {
    const object = {
      currPassword: obj.currPassword,
      newPassword: obj.newPassword,
    };

    try {
      setLoader(true);
      const response = await HttpService.CoreApiPost(
        "users/updatePassword",
        object
      );
      if (response) {
        setResponse(response?.data?.message);
        setSuccess(true);
        reset();
      } else {
        console.error("Password update failed:", response);
        setErr(true);
        console.log(response, "bad response");
      }
    } catch (error: any) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        setResponse(error.response.data.message);
        setLoader(false);
      } else {
        console.error("Unexpected error during password reset:", error);
      }
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await UserService.getAssistantName();
        reset({
          assistantName: response?.data?.body[0]?.botName,
        });
      } catch (error) {}
    })();
  }, []);

  return (
    <DashboardLayout heading="Settings">
      <Snackbar
        open={success ? success : err}
        autoHideDuration={10}
        message={response ? response : null}
      />

      <Box>
        <Box sx={{ backgroundColor: "#ffffff", borderRadius: "10px" }}>
          <Box
            sx={{
              backgroundColor: "#0062ff",
              padding: "10px",
              borderRadius: "10px 10px 0 0",
            }}
          >
            <Typography
              sx={{ fontSize: "24px", fontWeight: "700", color: "#ffffff" }}
            >
              Profile & Settings
            </Typography>
          </Box>
          <Box sx={{ padding: "20px" }}>
            <Typography
              sx={{
                display: "flex",
                gap: 1,
                alignItems: "center",
                fontSize: "20px",
                fontWeight: 700,
                mt: 1,
              }}
            >
              <LockIcon />
              Change Password
            </Typography>
            <form
              onSubmit={handleSubmit((data) => changePasswordHandler(data))}
              className="flex flex-col w-[50%]"
            >
              <Box
                sx={{
                  alignItems: "center",
                  position: "relative",
                  "& label": {
                    border: "none",
                  },
                  "& input": {
                    border: "0px solid #ccc",
                    borderRadius: "4px",
                    paddingLeft: "8px",
                    flex: 1,
                  },
                }}
              >
                <Input
                  label=""
                  name="currPassword"
                  control={control}
                  placeholder="Enter your Current Password"
                  rules={{ required: "Current Password is required" }}
                  type={showPassword ? "text" : "password"}
                  sx={{ width: "70%", marginTop: 2, marginBottom: 0.4 }}
                  endicon={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        //   onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <Input
                  label=""
                  name="newPassword"
                  control={control}
                  placeholder="Enter your New Password"
                  rules={{ required: "New Password is required" }}
                  type={showNewPassword ? "text" : "password"}
                  sx={{ width: "70%", marginTop: 2, marginBottom: 0.4 }}
                  endicon={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowNewPassword}
                        //   onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showNewPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Box>
              <Button
                label="Change Password"
                type="submit"
                loading={loader}
                sx={{
                  alignSelf: "self-start",
                  mt: 3,
                  width: "70%",
                  fontSize: "12px",
                  background: "#0090FF",
                  ":hover": {
                    background: "#0090FF",
                  },
                }}
              />
            </form>
          </Box>
        </Box>
      </Box>
    </DashboardLayout>
  );
};
export default UserProfile;
