import { Box } from "@mui/material";
import React, { ReactNode } from "react";
import { CustomStyles } from "types/common.types";
import defaultStyles, { StylesClasses } from "./styles";
import { getStyles } from "utils/common";

type AuthLayoutProps = {
  children: ReactNode;
  customStyles?: CustomStyles<StylesClasses>;
  image: string;
  design: string;
};

const AuthLayout = ({
  children,
  customStyles,
  image,
  design,
}: AuthLayoutProps) => {
  const styles = getStyles<StylesClasses>(defaultStyles, customStyles);

  return (
    <Box {...styles("wrapper")}>
      <Box {...styles("content")}>{children}</Box>
    </Box>
  );
};

export default AuthLayout;
