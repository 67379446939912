import { GoogleAuthType } from "types/form.types";
import { HttpService } from "./httpservice";

export class AuthService {

  public static async login(email: string, password: string) {
    return await HttpService.CoreApiPost("users/login", {
      email,
      password,
    });
  }

  public static signup(email: string, password: string) {
    return HttpService.CoreApiPost("users", {
      email,
      password,
    });
  }

  public static verify(UUID: string) {
    return HttpService.CoreApiGet(`users/verifyUser/${UUID}`)
  }
  public static verifyEmail(obj : any) {
    return HttpService.CoreApiPost('verifyEmail' , obj)
  }

  public static socialAuth(obj: GoogleAuthType) {
    return HttpService.CoreApiPost("users/social-login", obj)
  }

  public static guestLogin() {
    return HttpService.CoreApiGet(`users/guest-login`)
  }
}
