import { useState } from "react";

import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Button from "components/common/Button";
import UserForm from "components/feature/UserForm";
import { AuthLayout } from "layout";
import { useForm } from "react-hook-form";
import { UserDetailsForm } from "types/form.types";
import { NetworkResponse } from "types/network.types";
import { AuthService } from "../../services/authservice";
import routes from "router/routes";
import { Box } from "@mui/material";
import PasswordStrengthBar from "react-password-strength-bar";

export const SignUpPage = () => {
  const [loader, setLoader] = useState<boolean>(false);
  const navigate = useNavigate();
  const { handleSubmit, control, setError, watch } = useForm<UserDetailsForm>({
    defaultValues: {
      email: "",
      password: "",
    },
    mode: "onTouched",
  });
  const passwordState = watch("password");
  const handleSignup = async (login: UserDetailsForm) => {
    try {
      setLoader(true);
      const { data }: NetworkResponse<{ token: string; email: string }> =
        await AuthService.signup(login.email, login.password);
      setLoader(false);
      navigate(routes.verifyEmail.replace(":emailId", data.body.email));
    } catch (error: any) {
      setLoader(false);
      setError("email", { message: error?.response?.data?.message });
    }
  };

  return (
    <AuthLayout
      image="/images/lawyer.png"
      design="absolute -top-[20%] right-0 w-[100%] bottom-3 "
    >
      <Box className="border border-[#808080] shadow-md  min-h-[90vh] p-3">
        <div className="flex items-center gap-2">
          <div>
            <img src="/images/kolabryaLogo.png" />
          </div>
          <div className="text-[32px] font-bold">Kolabrya</div>
        </div>
        <div className="pl-10 mt-5">
          <h2 className="text-[41px] font-bold">Sign up</h2>
          <p className="text-[18px] w-[70%]">
            Register yourself for the best meeting intelligence tool out there.
          </p>
          <form
            onSubmit={handleSubmit((data) => {
              handleSignup(data);
            })}
            className="mt-12 flex flex-col items-center w-full"
          >
            <UserForm control={control} />
            <Box
              sx={{
                marginTop: "20px",
                width: "100%",
                display: "flex",
                justifyContent: "start",
              }}
            >
              <PasswordStrengthBar
                className="w-[70%]"
                password={passwordState}
              />
            </Box>
            <Button
              label="Sign up"
              type="submit"
              loading={loader}
              sx={{
                alignSelf: "self-start",
                mt: 5,
                width: "70%",
                fontSize: "20px",
              }}
            />
          </form>

          <p className="mt-7 text-[18px]">
            Already have an account? Click{" "}
            <Link className="text-blue-500" to={routes.login}>
              here
            </Link>{" "}
            to Sign in.
          </p>
        </div>
      </Box>
    </AuthLayout>
  );
};
