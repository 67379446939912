import { Styles } from "types/common.types";

const styles: Styles<StylesClasses> = {
      container: {
            display: 'flex',
            height: '100%'
          },
          left: {
            background: 'white',
            padding: '20px 20px',
            width: '340px',
            display: 'flex',
            flexDirection: 'column',
            flex: '0 0 auto' 
          },
          right: {
            flex: '1',
            padding: '20px 20px',
            overflowY: 'auto' 
          },
      active: {
            background: '#EEF2F9',
            color: 'black',
            border: '2px solid black',
            "& div, svg": {
                  fontWeight: '600',
                  color: 'black'
            }
      },
      item: {
            display: 'flex',
            padding: '10px 20px',
            marginTop: '5px',
            color: '#7E7F8E',
            alignItems: 'center',
            fontSize: '22px',
            borderRadius: '40px',
            "&:hover": {
                  background: '#eef2f970',
                  cursor: 'pointer',
                  border: '1px solid black',

            }
      },
      itemText: {
            marginLeft: '20px'
      },
      heading: {
            fontSize: '32px',
            fontWeight: '700',
            lineHeight: '45px',
            marginBottom: '40px'
      },
      logout: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '10px 20px',
            color: '#7E7F8E',
            borderRadius: '4px',
            fontSize: '18px',
            fontWeight: '600',
            border: '3px solid #dedede',
            marginTop: 'auto',
            "&:hover": {
                  cursor: 'pointer',
                  color: 'black'
            }
      }
};

type StylesClasses =
      | "container"
      | "item"
      | "heading"
      | "itemText"
      | "active"
      | "left"
      | "right"
      | "logout"

export default styles;
