import { Styles } from "types/common.types";

export type StylesClasses =
  | "wrapper"
  | "backgroundSection"
  | "heading"
  | "description"
  | "image"
  | "content";

const styles: Styles<StylesClasses> = {
  wrapper: {
    height: "100vh",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    backgroundColor: "#00b4d8",
  },
  backgroundSection: {
    position: "relative",
    // overflow: "hidden",
    flexBasis: "45%",
    backgroundColor: "primary.main",
    color: "#FFF",
    padding: "100px 35px",
    // padding: "80px 0",
    margin: "20px",
    borderRadius: "12px",
  },
  heading: {
    fontSize: "52px",
    fontWeight: 600,
    letterSpacing: "-3px",
    lineHeight: "55px",
    marginBottom: "20px",
  },
  description: {
    fontSize: "24px",
    fontWeight: 400,
    lineHeight: "30px",
    letterSpacing: "-1.6px",
  },
  image: {
    // maxHeight: "50vh",
    position: "absolute",
    inset: "auto 0 0 0",
    zIndex: 1,
  },

  content: {
    alignSelf: "center",
    width: "50%",
    // flexBasis: "55%",
    padding: "2% 5%",
    "@media (max-width: 1280px)": {
      width: "80%",
    },
  },
};

export default styles;
