import { Styles } from "types/common.types";

const headerHeight = "80px";

const styles: Styles<StylesClasses> = {
  wrapper: {
    display: "flex",
    height: "100%",
    position: "relative",
  },

  initials: {
    backgroundColor: "custom.background",
    fontWeight: "bold",
    fontSize: "22px",
    textTransform: "uppercase",
    padding: "10px",
    borderRadius: "50%",
    color: "custom.label",
    height: "52px",
    width: "52px",
    textAlign: "center",
  },

  yNav: {
    width: "70px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: "12px",
    position: "relative",
    // boxShadow: "4px 75px 8px -7px #b5b5b559",
    padding: "5px",
  },

  container: {
    flexGrow: 1,
  },

  xNav: {
    position: "relative",
    height: `${headerHeight}`,
    display: "flex",
    alignItems: "center",
    padding: "0 20px",
    boxShadow: "1px 7px 8px -7px #b5b5b559",
  },

  main: {
    backgroundColor: "custom.background",
    // height: `calc(100vh - ${headerHeight})`,
    // overflow: "auto",
    padding: "10px",
  },

  yNavItems: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
    flexGrow: 1,
    "& img": {
      cursor: "pointer",
    },
  },

  expandArrow: {
    height: "40px",
    width: "40px",
    padding: "5px",
    backgroundColor: "custom.background",
    borderRadius: "4px",
    margin: "20px 0",
    cursor: "pointer",
  },

  xNavItems: {
    flexGrow: 1,
  },

  menuItem: {
    borderRadius: "8px",
    boxSizing: "content-box",
  },
  modalWrapper: {
    background: "white",
    width: "350px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    boxShadow: 24,
    p: "20px 30px",
    borderRadius: "8px",
  },
  closeIcon: {
    position: "absolute",
    top: 5,
    right: 5,
  },
};

export type StylesClasses =
  | "wrapper"
  | "main"
  | "container"
  | "initials"
  | "xNav"
  | "yNav"
  | "xNavItems"
  | "yNavItems"
  | "expandArrow"
  | "menuItem"
  | "modalWrapper"
  | "closeIcon";

export default styles;
