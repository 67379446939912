import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Box,
  Select,
  InputLabel,
  MenuItem,
  createTheme,
  ThemeProvider,
  IconButton,
  CircularProgress,
} from "@mui/material";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import { LoadingButton } from "@mui/lab";
import { useForm } from "react-hook-form";
import { useEffect } from "react";

interface DisputePopupModalProps {
  isOpen: boolean;
  handleCancellation: () => void;
  dialogTitle: string;
  isAdmin: boolean;
  handleApprove: (uuid: string) => void;
  handleDispute: (uuid: string) => void;
  handleDeny: (uuid: string) => void;
  reason: string;
  comment: string;
  setReason: (reason: string) => void;
  setComment: (comment: string) => void;
  popupUuid: string;
  setPopupUuid: (popupUuid: string) => void;
  loading: boolean;
  onlyView: boolean;
}
const DisputePopupModal: React.FC<DisputePopupModalProps> = ({
  isOpen,
  handleCancellation,
  dialogTitle,
  isAdmin,
  handleApprove,
  handleDispute,
  handleDeny,
  reason,
  comment,
  setReason,
  setComment,
  popupUuid,
  setPopupUuid,
  loading,
  onlyView,
}) => {
  const reasonList = [
    "Wrong Number",
    "Looking For Another Company",
    "Does Not Speak English",
    "Outside Service Area",
    "Do Not Service Issue",
    "Other - Please fill in comments below",
  ];
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      reason,
      comment,
    },
  });

  useEffect(() => {
    setValue("reason", reason);
    setValue("comment", comment);
  }, [reason, comment, setValue]);

  const onSubmit = (data: any) => {
    handleDispute(popupUuid);
  };

  const theme = createTheme({
    components: {
      MuiInputLabel: {
        styleOverrides: {
          root: {
            marginBottom: "8px", // Global margin-bottom override
            fontWeight: 700,
            color: "#0F1826",
          },
        },
      },
    },
    typography: {
      fontFamily: ["Inter", "sans-serif"].join(", "),
    },
  });

  const handleClose = () => {
    setPopupUuid("");
    handleCancellation();
    setReason("");
    setComment("");
  };
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        position: {
          xs: "absolute",
          md: "fixed",
        },
        // width: "100%",
        "& .MuiDialog-paper": {
          width: { xs: "80%", md: "900px" },
          padding: 2, // Adjust padding here as needed
        },
      }}
    >
      <DialogTitle
        sx={{
          borderBottom: "1px solid",
          marginBottom: 4,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography sx={{ flexGrow: 1, textAlign: "center", fontSize: 32 }}>
          {dialogTitle}
        </Typography>
        <Box>
          <IconButton onClick={handleClose}>
            <HighlightOffOutlinedIcon />
          </IconButton>
        </Box>
      </DialogTitle>

      <ThemeProvider theme={theme}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {loading ? (
            <div className="flex justify-center items-center h-[43vh]">
              <CircularProgress size={50} />
            </div>
          ) : (
            <DialogContent>
              <Box className="px-3">
                <InputLabel id="reason-label">Reason for dispute</InputLabel>
                <Select
                  {...register("reason", {
                    required: "Please select a reason",
                  })}
                  labelId="reason-label"
                  id="demo-simple-select"
                  value={reason}
                  onChange={(e) => {
                    setReason(e.target.value);
                  }}
                  className="w-full"
                  defaultValue="Select an Option"
                  disabled={isAdmin || onlyView}
                >
                  <MenuItem value="" disabled>
                    Select an Option
                  </MenuItem>

                  {reasonList.map((item: string, index: number) => (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
                {errors.reason && (
                  <Typography color="error">{errors.reason.message}</Typography>
                )}

                <Box
                  sx={{
                    textarea: {
                      width: "100%",
                      border: "1px solid #0b5fff",
                      borderRadius: "10px",
                      outline: "none",
                      p: "10px",
                    },
                    mt: "30px",
                  }}
                >
                  <InputLabel id="comment-label">Comments </InputLabel>
                  <textarea
                    {...register("comment", {
                      required: "Comments are required for all disputes", 
                      validate: (value) =>
                        reason !== "Other - Please fill in comments below" ||
                        value.trim() !== "" ||
                        "This field is required according to your provided reason",
                    })}
                    value={comment || ""}
                    onChange={(e) => setComment(e?.target?.value)}
                    rows={5}
                    placeholder={
                      comment ||
                      "Please explain why this call is being disputed"
                    }
                    disabled={isAdmin || onlyView}
                  />
                  {errors.comment && (
                    <Typography color="error">
                      {errors.comment.message}
                    </Typography>
                  )}
                </Box>
              </Box>
            </DialogContent>
          )}
          <DialogActions>
            {isAdmin ? (
              <>
                <LoadingButton
                  variant="contained"
                  color="error"
                  onClick={() => handleDeny(popupUuid)}
                  loading={loading}
                  disabled={onlyView}
                >
                  Deny
                </LoadingButton>
                <LoadingButton
                  variant="contained"
                  onClick={() => handleApprove(popupUuid)}
                  loading={loading}
                  autoFocus
                  disabled={onlyView}
                >
                  Approve
                </LoadingButton>
              </>
            ) : (
              <>
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleCancellation}
                >
                  Cancel
                </Button>
                <LoadingButton
                  variant="contained"
                  // onClick={() => handleDispute(popupUuid)}
                  type="submit"
                  autoFocus
                  loading={loading}
                  disabled={onlyView}
                >
                  Submit
                </LoadingButton>
              </>
            )}
          </DialogActions>
        </form>
      </ThemeProvider>
    </Dialog>
  );
};

export default DisputePopupModal;
