import { Styles } from "types/common.types";

const emailVerificationStyles: Styles = {
  wrapper: {
    position: "relative",
    // height: "calc(100vh - 40px)",
    margin: "20px",
    padding: "8% 10%",
    borderRadius: "8px",
    color: "white",
    backgroundColor: "primary.main",
  },
  wrappers: {
    position: "relative",
    // height: "calc(100vh - 40px)",
    margin: "20px",
    padding: "8% 10%",
    borderRadius: "8px",
    color: "white",
    backgroundColor: "primary.main",
  },
  backBtn: {
    border: "1px solid white",
    width: "max-content",
    padding: "2px 20px",
    borderRadius: "15px",
    marginBottom: "25px",
    cursor: "pointer",
  },
  main: {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
  },
  heading: {
    fontSize: "60px",
    fontWeight: 600,
    letterSpacing: "-0.05em",
    lineHeight: "72px",
  },
  email: {
    fontSize: "32px",
    fontWeight: 400,
    letterSpacing: "-0.05em",
  },
  resend: {
    fontSize: "24px",
    fontWeight: 400,
    letterSpacing: "-0.05em",
  },
  bgImg: {
    position: "absolute",
    bottom: 0,
    right: 0,
    width: "50%",
  },
  anotherImg: {
    width: "500px",
  },
};

const welcomePageStyles: Styles = {
  layoutWrapper: { padding: 0 },
  wrapper: {
    padding: "10% 0 0 10%",
    position: "relative",
    height: "100%",
    marginBottom: "-10px",
  },
  buttons: {
    width: "30px",
    height: "30px",
    borderRadius: "24px",
    background: "#E1EEF9",
    fontWeight: 600,
    textAlign: "center",
    padding: "3px",
  },
  navBar: {
    display: "flex",
    justifyContent: "end",
    gap: "10px",
    pr: "10px",
  },
  heading: {
    fontSize: "60px",
    fontWeight: 600,
    lineHeight: "72px",
    letterSpacing: "-0.05em",
  },
  description: {
    fontSize: "32px",
    fontWeight: 400,
    lineHeight: "48px",
    letterSpacing: "-0.05em",
  },
  startSession: {
    marginTop: "70px",
  },
  beginText: {
    fontSize: "24px",
    fontWeight: 400,
    lineHeight: "36px",
    padding: "5px 0px",
    letterSpacing: "-0.05em",
  },
  imageWrapper: {
    position: "absolute",
    height: 300,
    width: 300,
    bottom: 0,
    right: 0,
    "& .circle": {
      position: "absolute",
      bottom: 125,
      right: 265,
      height: 70,
      zIndex: 1,
    },
    "& .intelligence": {
      position: "absolute",
      bottom: 0,
      right: 200,
      height: 200,
      zIndex: 3,
    },
    "& .fc-theme-standard td": {
      border: "none",
    },
  },
};

export { emailVerificationStyles, welcomePageStyles };
