export class EnvironmentService {
  public static getCoreServerHost() {
    // return process.env.REACT_APP_CORE_BASE_URL ?? "http://localhost:3003/api";
    return (
      process.env.REACT_APP_CORE_BASE_URL ??
      "https://api.consumerave.com/api"
      
    );
    // return "http://localhost:3003/api";
  }

  public static getServerHost() {
    return process.env.REACT_APP_AMAZING_BASE_URL ?? "http://localhost:8080";
  }
}
