import React from "react";
// eslint-disable-next-line
import { createBrowserRouter, RouterProvider, Route } from "react-router-dom";
import { VerifyEmail } from "pages/verify-email/verify-email";
import { EmailVerification } from "pages/email-verification/email-verification";
import { LoginPage } from "pages/login/login-page";
import { SignUpPage } from "pages/signup/signup-page";
import ProtectedRoute from "router/ProtectedRoute";
import Profile from "pages/settings/profile/profile";
import Security from "pages/settings/security/security";
import Settings from "pages/settings/settings";
import AuthRoute from "./AuthRoute";
import PublicRoute from "./PublicRoute";
import routes from "./routes";
import ForgetPassword from "pages/forgetPassword/forgetPassword";
import ChangePassword from "pages/forgetPassword/changePassword";
import UserProfile from "pages/user-profile/userProfile";
import Home from "pages/mainPage/home";

// Define your routes using createBrowserRouter
const router = createBrowserRouter([
  {
    path: routes.login,
    element: <AuthRoute Component={LoginPage} className="h-full bg-[#00b4d8]" />,
  },
  {
    path: routes.signUp,
    element: <AuthRoute Component={SignUpPage} />,
  },
  {
    path: routes.verifyEmail,
    element: <PublicRoute Component={VerifyEmail} />,
  },
  {
    path: routes.emailVerification,
    element: <PublicRoute Component={EmailVerification} />,
  },
  {
    path: routes.forgetPassword,
    element: <PublicRoute Component={ForgetPassword} />,
  },
  {
    path: routes.changePasswrd,
    element: <PublicRoute Component={ChangePassword} />,
  },
  {
    path: routes.home,
    element: <ProtectedRoute Component={Home} />,
  },
  {
    path: routes.callHistory,
    element: <ProtectedRoute Component={Home} />,
  },
  {
    path: routes.password,
    element: <ProtectedRoute Component={UserProfile} />,
  },
  {
    path: routes.settings,
    element: <ProtectedRoute Component={Settings} />,
    children: [
      {
        path: "profile",
        element: <ProtectedRoute Component={Profile} />,
      },
      {
        path: "security",
        element: <ProtectedRoute Component={Security} />,
      },
    ],
  },
]);

const AppRouter = () => {
  return <RouterProvider router={router} />;
};

export default AppRouter;
