import { Box, IconButton, InputAdornment } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AuthLayout } from "layout";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { changePassword } from "types/form.types";
import { AuthService } from "../../services/authservice";
import routes from "router/routes";
import Input from "components/form/Input";
import Button from "components/common/Button";
import { HttpService } from "services/httpservice";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import PasswordStrengthBar from "react-password-strength-bar";
import { toast } from "react-toastify";

const ChangePassword = () => {
  const { UUID } = useParams<{ UUID: string }>();
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState(false);
  const [email, setEmail] = useState("");

  const verifyEmailHandler = async () => {
    let res = await AuthService.verifyEmail({ id: UUID });
    setEmail(res?.data?.body?.email);
  };

  useEffect(() => {
    if (UUID) {
      verifyEmailHandler();
    } else {
      setDisabled(true);
    }
  }, [UUID]);

  const { handleSubmit, control, watch } = useForm<changePassword>({
    defaultValues: {
      password: "",
    },
    mode: "onTouched",
  });

  const passwordState = watch("password");
  const [loader, setLoader] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [showPassword, setShowPassword] = useState(false);

  const changePasswordHandler = async (obj: any) => {
    const object = {
      email: email,
      password: obj.password,
      confirm_password: obj.password,
    };
    try {
      setLoader(true);
      const response = await HttpService.CoreApiPost(
        "users/updateOpenPassword",
        object
      );

      if (response.status >= 200 && response.status < 300) {
        localStorage.clear();
        console.log("Password reset successful:", response.data);
        toast.success(
          "Password Reset Successfully! Redirecting to login screen..."
        );
        setError(null);
        setDisabled(true);
        setTimeout(() => {
          navigate(routes.login);
        }, 5000);
      } else if (response.status === 400) {
        setError("Invalid request. Please check your email format.");
      } else {
        setError(
          `Error during password reset. Server response: ${response.status}`
        );
      }
    } catch (error) {
      setError("An unexpected error occurred.");
      console.error("Unexpected error during password reset:", error);
    } finally {
      setLoader(false);
    }
  };

  const handleClickShowPassword = () =>
    setShowPassword((show: boolean) => !show);

  return (
    <AuthLayout
      image="/images/auth-cover.jpg"
      design="absolute right-0 w-[100%] bottom-0"
    >
      <Box className="shadow-xl  min-h-[90vh] p-4 mb-3 bg-white">
        <Box className="flex flex-col justify-center items-center gap-2 p-4">
          <Box className="flex flex-col justify-center items-center">
            <h2 className="text-[41px] font-bold text-center">
              Reset Password
            </h2>
            <p className="text-[16px] mt-28 text-center">
              Please enter your new password
            </p>
          </Box>
          <form
            onSubmit={handleSubmit((data) => changePasswordHandler(data))}
            className="mt-2 flex flex-col justify-center items-center self-center w-full"
          >
            <Box className="w-[90%] md:w-[70%]">
              <Input
                label="Password"
                name="password"
                type={showPassword ? "text" : "password"}
                control={control}
                rules={{
                  required: "Password is required",
                }}
                endicon={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Box>
            <Box sx={{ marginTop: "20px", width: "70%" }}>
              <PasswordStrengthBar password={passwordState} />
            </Box>
            <Button
              label="Reset Password"
              type="submit"
              loading={loader}
              disabled={disabled}
              className=""
              sx={{
                mt: 5,
                minWidth: {
                  xs: "30%",
                  md: "60%",
                },
                fontSize: {
                  xs: "17px",
                  md: "20px",
                },
              }}
            />
          </form>
        </Box>
        <Box className="flex justify-center items-center text-center text-[16px] mt-2 w-[60%] ">
          {error && <p style={{ color: "red" }}>{error}</p>}
        </Box>
        <Box className="flex justify-center">
          <p className="mt-5 text-[18px]">
            <Link className="text-blue-500" to={routes.login}>
              Return
            </Link>{" "}
            to log in{" "}
          </p>
        </Box>
      </Box>
    </AuthLayout>
  );
};

export default ChangePassword;
