import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import persistReducer from "redux-persist/es/persistReducer";
import storage from "redux-persist/lib/storage";
import authReducer from "./slices/auth-slice";
import userReducer from "./slices/user-slice";
import callReducer from "./slices/call-slice";

const rootPersistConfig = {
  key: "root",
  storage: storage,
  whitelist: ["userDetails"],
};

const userReducerConfig = {
  key: "userReducer",
  storage: storage,
};

const rootReducer = combineReducers({
  authReducer: authReducer,
  userReducer: persistReducer(userReducerConfig, userReducer),
  calls: callReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
